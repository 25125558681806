import {$id, gti, gup} from "./helpers";
import {$qs, appendHTML, forAll} from "./dom";
import {loadScript} from "./load_assets";
import {GAEvent} from "./ga";
import {addUnLoadEvent} from "./events";
import {config} from "./config";

//https://dv8.edf.org/article/bill-nye-climate-guy
//https://dv8.edf.org/whats-fastest-way-slow-global-warming-bill-nye-has-answer
//https://dv8.edf.org/ecosystems/sustainable-agriculture/precision-agriculture/video
//https://globalcleanair.org/

//https://test-vital-signs-edf.pantheonsite.io/story/4-ways-hurricanes-are-becoming-more-dangerous-and-why : https://test-vital-signs-edf.pantheonsite.io/media/oembed?url=https%3A//www.youtube.com/watch%3Fv%3DDDgob-vR2Hg&max_width=0&max_height=0&hash=8Q_YWddLMmA-ASoWMN7pIssOkkFx5Z22QDPpKqORt6c

//https://dv8.edf.org/PortraitsOfChange
//https://dv8.edf.org/oceans/protecting-our-oceans-supporting-our-fishermen

let loading_yt,
    yt_loaded,
    yt_player,
    yt_player_id,
    yt_video_id,
    yt_player_status,
    yt_last_played_at,
    yt_total_play_time,
    category = 'YouTube Video',
    yt_videos = {},
    player_exist_interval;

export function attachYoutube(selector) {
    forAll(selector, function (iframe) {
        let src;

        if (selector.indexOf('/media/oembed') > -1){
            src = gup('url', iframe.src);

            if (!src){
                return;
            }

            src = decodeURIComponent(src);
        }

        src = src || iframe.src;

        let video_id = getVideoId(src),
            id = iframe.id || video_id;

        if (!video_id){
            return;
        }

        if (iframe.closest('div[data-edf="true"]')){
            return;
        }

        iframe.id = id;
        iframe = $id(id);

        let height = iframe.getAttribute('data-height') || iframe.height || iframe.style.height || iframe.offsetHeight || 'auto',
            width = iframe.getAttribute('data-width') || iframe.width || iframe.style.width || iframe.offsetWidth || '100%';

        iframe.outerHTML = '<div data-edf="true" id="'+id+'" style="'+(iframe.getAttribute('style')||'')+'" class="'+iframe.className+'" title="'+(iframe.title||'')+'"></div>';

        youtube_load({just_load: true}, function () {
            youtube_player({
                video_id: video_id,
                player: id,
                primary: false,
                height: height+'',
                width: width+''
            });
        });
    });
}

export function youtube_load(opts, cb){
    if (!opts){
        opts = {};
    }

    cb = cb || function(){};

    if (!opts.just_load){
        if (!$id(opts.player_id)){
            appendHTML({
                a: $qs('body'),
                elem: 'div',
                id: 'yt_player'
            });
        }
    }

    if (opts.video_id){
        yt_video_id = opts.video_id.split('?')[0].split('&')[0];
    }

    if (opts.player_id){
        yt_player_id = opts.player_id;
    }

    let wait = setInterval(function(){
        if (window.YT && YT.Player){
            clearInterval(wait);

            if (!opts.just_load){
                youtube_player();
            }

            cb();
        }
    }, 25);

    if (typeof window.YT !== 'object' && !loading_yt){
        loading_yt = true;

        // window.youtube_player = youtube_player;
        // window.youtube_no_player = youtube_no_player;
        // window.onYouTubeIframeAPIReady = window[opts.just_load?'youtube_no_player':'youtube_player'];

        loadScript('https://www.youtube.com/iframe_api');
    }
}

export function youtube_no_player(){
    loading_yt = false;
    yt_loaded = true;
}

export function youtube_player(opts){
    loading_yt = false;
    yt_loaded = true;

    opts = opts || {};

    if (opts.video_id){
        yt_video_id = opts.video_id.split('?')[0].split('&')[0]; //hedge against QB2RLS607lY?rel=0&modestbranding=1
    }

    if (!yt_video_id){
        return;
    }

    yt_player_id = opts.player || yt_player_id;

    let height = opts.height || ($id(yt_player_id) && $id(yt_player_id).getAttribute('data-height') ? $id(yt_player_id).getAttribute('data-height') : 'auto'),
        events = {
            'onStateChange': youtube_change,
            'onDestroy': youtube_reset,
            'onError': youtube_error,
            //'onApiChange': console.log
        };

    if (opts.primary === false){
        // events['onReady'] = function(e){
        //     console.log(e)
        // };
    }
    else {
        events['onReady'] = youtube_play;
    }

    let player = new YT.Player(yt_player_id, {
        videoId : yt_video_id,
        width : opts.width || '100%',
        height: height,
        playerVars: {
            rel: 0,
            modestbranding: 1,
            enablejsapi: 1,
            origin: config.HN,
            widget_referrer: config.HN+config.PN
        },
        events: events
    });

    if (opts.primary === false){
        setTimeout(function(){
            poller(player);
        }, 50);
    }

    if (opts.primary){
        yt_player = player;
    }

    if (!yt_videos[yt_video_id]){
        yt_videos[yt_video_id] = {
            duration: null,
            current_time: 0,
            actions: [],
            starts: 0
        }
    }

    yt_videos[yt_video_id].playing = false;
    yt_videos[yt_video_id].player = player;
    yt_videos[yt_video_id].state = 100;
}

function poller(player){
    clearInterval(player_exist_interval);

    let cycles = 0;
    player_exist_interval = setInterval(function(){
        if (yt_videos[yt_video_id] && yt_videos[yt_video_id].state === 100 && cycles < 4){
            return;
        }

        if (!player || !player.playerInfo || !yt_player_id){
            clearInterval(player_exist_interval);
            return youtube_reset();
        }

        if (player.h && player.h.id
            && player.h.tagName === 'IFRAME' && !$id(player.h.id)
        ){
            clearInterval(player_exist_interval);
            return youtube_reset();
        }

        get_duration(player, yt_video_id);
        get_current_time(player, yt_video_id);

        cycles++;
    }, 1000);
}

function youtube_play(e){
    yt_video_id = e.video_id;

    if (!e.target && !yt_player){
        return;
    }

    (e.target || yt_player).playVideo();
    poller(e.target || yt_player);
}

function youtube_error(e){
    GAEvent('exception', { //https://developers.google.com/youtube/iframe_api_reference#onError
        ec: category,
        description: e.data
    });
}

function get_current_time(yt_player, yt_video_id){
    if (!yt_player || !yt_video_id) {
        return;
    }

    let time = 0;
    if (typeof yt_player.currentTime === 'number'){
        time = yt_player.currentTime;
    }
    else if (yt_player && typeof yt_player.getCurrentTime === 'function'){
        time = yt_player.getCurrentTime();
    }
    else if (yt_player.playerInfo && yt_player.playerInfo.currentTime){
        time = yt_player.playerInfo.currentTime;
    }

    if (time){
        yt_videos[yt_video_id].current_time = time;
    }

    return time;
}

function get_duration(yt_player, yt_video_id){
    if (!yt_player || !yt_video_id) {
        return null;
    }

    let duration = 0;
    if (typeof yt_player.duration === 'number'){
        duration = yt_player.duration;
    }
    else if (typeof yt_player.getDuration === 'function'){
        duration = yt_player.getDuration();
    }
    else if (yt_player.playerInfo && yt_player.playerInfo.duration){
        duration = yt_player.playerInfo.duration;
    }

    if (duration){
        yt_videos[yt_video_id].duration = duration;
    }

    return duration;
}

function youtube_change(e){
    let video_id = yt_video_id,
        player = yt_player,
        timestamp = gti();

    if (!video_id){
        if (e.target && e.target.playerInfo && e.target.playerInfo.videoData){
            video_id = e.target.playerInfo.videoData.video_id;
        }

        if (e.playerInfo && e.playerInfo.videoData){
            video_id = e.playerInfo.videoData.video_id;
        }

        if (!video_id){
            return;
        }
    }

    if (!player){
        if (e.target && e.target.playerInfo){
            player = e.target.playerInfo;
        }

        if (e.playerInfo){
            player = e.playerInfo;
        }

        if (!player){
            return;
        }
    }

    //Reference: https://developers.google.com/youtube/iframe_api_reference#Playback_status

    let duration = get_duration(player, video_id) || yt_videos[video_id].duration,
        current_time = get_current_time(player, video_id) || yt_videos[video_id].current_time;

    yt_videos[video_id].player = player;

    if (e.data === -1 && yt_videos[video_id].starts === 0){
        yt_videos[video_id].playing = false;

        GAEvent('video_view', {
            ec: category,
            el: 0,
            ev: 0,
            af: video_id,
            at: 'destroy'
        });
    }
    else if (e.data === 0){
        yt_videos[video_id].playing = false;

        //Event records video end signal and passes video duration (if knowable) as event value
        GAEvent('video_view', {
            ec: category,
            el: duration,
            ev: current_time,
            af: video_id,
            at: 'end'
        });
    }
    else if (e.data === 1){
        yt_videos[video_id].playing = true;
        yt_videos[video_id].starts++;

        //Event records video play signal and passes video duration (if knowable) as event value
        GAEvent('video_view', {
            ec: category,
            el: duration,
            ev: current_time,
            af: video_id,
            at: 'play',
            ei: 1
        });

        yt_last_played_at = timestamp;
    }
    else if (e.data === 2){
        yt_videos[video_id].playing = false;

        //Event records video stop signal and passes video duration (if knowable) as event value
        GAEvent('video_view', {
            ec: category,
            el: duration,
            ev: current_time,
            af: video_id,
            at: 'stop'
        });
    }
    else if (e.data === 3){
        //Event records video buffer signal and passes video duration (if knowable) as event value. Previously, this was passed to GA as `Start`. However, this action has been updated to reflect YT's API names. Technically, depending on real-world usage, this event can fire more than just on start depending on data rates and buffering.
        GAEvent('video_view', {
            ec: category,
            el: duration,
            ev: current_time,
            af: video_id,
            at: 'buffer'
        });
    }
    else if (e.data === 5){
        //Event records video cue signal.....essentially a singular, "pre-start" event
        GAEvent('video_view', {
            ec: category,
            el: duration,
            ev: current_time,
            af: video_id,
            at: 'cue'
        });
    }

    if (yt_player_status === 1 && yt_last_played_at){
        let played_for_in_ms = timestamp-yt_last_played_at;
        yt_total_play_time = yt_total_play_time || 0;
        yt_total_play_time += played_for_in_ms;

        //Event records count of seconds since previous `Play` event was signaled. For reporting purposes, adding these event values could be a proxy for total/average on-site video view time.
        //Event records total seconds played for video. Will reflect the same count as event `Played for` for the first chunk of play time. For cases where the user plays the video all the way through and closes, these two events will record the same information. What distinguishes this `Total` event is if the user stops and starts the video several times, or even drags playback backwards, in which case, the total play time can exceed the video duration. For reporting purposes, you would want to isolate the last `Total` event for the video for that specific page view. Not sure if that is doable in GA UI, but easy enough via a utility report.

        let total_played = parseInt(yt_total_play_time/1000),
            percent_viewed,
            percent_marker;

        if (duration){
            //Event records percentage of video played based on total play time. CAUTION: based on the above description, total play time can count duplicate seconds of the same video content depending on user playback behavior. In most cases an event value of `100` can proxy signal that video played completely through, but that is technically not what is being tracked here. To be precise, it's the total seconds of play time divided by the video's duration (dependent on returned meta data). For reporting purposes, you would want to isolate the last `Total / duration` event for the video for that specific page view. Not sure if that is doable in GA UI, but easy enough via a utility report.
            //To be super precise about percent played and ensuring that total play times don't double count the same content seconds, i.e. getting unique video view seconds, additional dev work would be needed to more granularly track data. Or that information might already be available directly in YouTube Analytics (UI or API).
            percent_viewed = parseInt(((yt_total_play_time/1000)*100)/duration);

            //Event records the current time marker as a percentage of video duration. CAUTION: this reflects percentage of video based on the play marker at the time of event fire. A user could drag playback to video end and generate a 100% duration without actually viewing all seconds of the video. For reporting purposes, you would want to isolate a single event per video per pageview with the highest percentage. Not sure if that is doable in GA UI, but easy enough via a utility report.
            percent_marker = parseInt((current_time*100)/duration)
        }

        //using aliases to minimize footprint
        let viewage = {
            d: duration,
            c: current_time,
            p: played_for_in_ms,
            t: yt_total_play_time
        };

        yt_videos[video_id].played_for_in_ms = played_for_in_ms;
        yt_videos[video_id].yt_total_play_time = yt_total_play_time;

        if (percent_viewed){
            viewage.v = percent_viewed;
            viewage.m = percent_marker;

            yt_videos[video_id].percent_viewed = percent_viewed;
            yt_videos[video_id].percent_marker = percent_marker;
        }

        GAEvent('video_view', {
            ec: category,
            el: JSON.stringify(viewage),
            ev: total_played,
            af: video_id,
            at: 'viewage'
        });
    }

    yt_videos[video_id].actions.push({
        data: e.data,
        gti: timestamp,
        current_time: current_time
    });

    yt_player_status = e.data;
    yt_videos[video_id].state = e.data;
}

export function youtube_close(){
    if (!yt_video_id){
        return;
    }

    youtube_reset();
}

function youtube_reset(){
    if (!yt_video_id){
        return;
    }

    youtube_change({data: -1});

    if (yt_videos[yt_video_id]){
        yt_videos[yt_video_id].playing = null;
        yt_videos[yt_video_id].player = null;
    }

    if (yt_player){
        if (yt_player.destroy){
            yt_player.destroy();
        }

        yt_player = null;
    }

    yt_video_id = null;
    yt_player = null;
    yt_player_id = null;
    yt_player_status = null;
    yt_last_played_at = null;
    yt_total_play_time = null;
}

export function getVideoId(src){
    if (src.indexOf('/embed/') > -1){
        return src.split('/embed/')[1].split('?')[0].split('&')[0];
    }

    if (src.indexOf('/watch') > -1){
        return gup('v', src);
    }

    return null;
}

export function getVideos(){
    return yt_videos;
}

//TODO add cleanup events
addUnLoadEvent(function(){
    if (yt_video_id && yt_videos[yt_video_id] && yt_videos[yt_video_id].state >= 0 && yt_videos[yt_video_id].state < 6){
        youtube_reset();
    }
});

export {
    yt_loaded
}
