import { gti, qrySep, addQryString, _ef, enc } from './helpers';
import { sentryMessage } from './sentry';
import { userTiming } from './ga';
//import {addEvent} from './events';

export function ajaxReq(opts, callback) {
    function addQS(prop){
        opts.url = addQryString(opts.url, opts[prop]);
    }

    callback = callback || _ef;

    opts = typeof opts === 'object' ? opts : {url: opts};
    opts.method = opts.method || 'GET';

    if (opts.method.toLowerCase() === 'get') {
        addQS('data');

        if (!opts.cache){
            opts.url += qrySep(opts.url) + 'gti='+gti();
        }
    }
    else if (opts.method.toLowerCase() === 'post') {
        addQS('query');
    }

    if (opts.url.indexOf('.html') === opts.url.length - 5) {
        opts.format = 'text';
    }
    else if (opts.format === 'html'){
        opts.format = 'text';
    }

    let completed = false,
        aborted = false,
        start = new Date().getTime(),
        xhr = new XMLHttpRequest();

    // addEvent(xhr, 'error', console.log, {once: true});
    // addEvent(xhr, 'timeout', console.log, {once: true});
    // addEvent(xhr, 'load', console.log, {once: true});

    xhr.onreadystatechange = function() {
        if (aborted && xhr.status === 0){
            callback({error: 'Aborted by timeout'});
        }
        else if (xhr.readyState === 4 && !aborted) {
            let err = !(xhr.status >= 200 && xhr.status < 400),
                res = xhr.response || (opts.format === 'json' ? '' : xhr.responseText) || null;

            if (res && typeof res === 'string'){
                res = res.trim();
            }

            if (!err && res && opts.format === 'json') {
                if (typeof res === 'string') {
                    res = res.replace(/\n/g, '');

                    try {
                        res = JSON.parse(res);
                    } catch (e) {
                        err = true;
                    }
                }
            }

            if (xhr.status >= 400 && !opts.ignore_error){
                sentryMessage('AJAX request tanked: '+opts.url.split('?')[0], {
                    code: xhr.status,
                    format: opts.format,
                    method: opts.method,
                    response: res
                });
            }

            let timespan = gti(start);
            if (opts.timing){
                userTiming(typeof opts.timing === 'string' ? opts.timing : 'Ajax: '+new URL(opts.url).hostname, opts.method+': '+xhr.status, timespan);
            }

            completed = true;
            callback(err ? (res || err) : null, res, {
                timespan: timespan,
                xhr: xhr
            });
        }
    };

    xhr.open(opts.method, opts.url, opts.async !== false);

    if (typeof opts.timeout === 'number'){
        setTimeout(function(){
            if (!completed){
                aborted = true;
                xhr.abort();
            }
        }, opts.timeout < 1000 ? opts.timeout*1000 : opts.timeout);
    }

    opts.format = opts.format || 'json';

    if (opts.format === 'form') {
        xhr.setRequestHeader('Content-type', 'application/x-form-urlencoded; charset=UTF-8');
        opts.format = 'text';
    }

    if (opts.async !== false){
        xhr.responseType = opts.format;
    }

    if (opts.withCredentials) {
        xhr.withCredentials = true;
    }

    if (opts.format === 'json') {
        xhr.json = true;
        xhr.overrideMimeType('application/json');
    }

    if (opts.xhr) {
        for (let prop in opts.xhr) {
            xhr[prop] = opts.xhr[prop];
        }
    }

    if (opts.headers) {
        for (let prop in opts.headers) {
            xhr.setRequestHeader(prop, opts.headers[prop]);
        }
    }

    opts.data = opts.data || opts.body || null;

    if (opts.data && typeof opts.data === 'object' && opts.method.toLowerCase() === 'post') {
        if (opts.body_format === 'json') {
            xhr.send(JSON.stringify(opts.data));
        }
        else {
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            let encoded = [];
            for (let prop in opts.data) {
                encoded.push(enc(prop) + '=' + enc(opts.data[prop]));
            }

            xhr.send(encoded.join('&').replace(/%20/g, '+'));
        }
    }
    else {
        xhr.send();
    }
}