import {config} from './config';
import {gup, getParentDomain, $name, $id} from "./helpers";
import {_LS, loadAssets, overrideAppendChild, overrideInsertBefore} from "./load_assets";
import {allowCookies, blockCookies, eraseCookie, getCookies, listenCookieChange, cookie_consent_cookies} from "./storage";
import {appendHTML, forAll} from "./dom";
import {addEvent, addReadyEvent, fireCustomEvent} from "./events";
import {GAEvent} from "./ga";
import {dataLayer} from "./datalayer";
import {ajaxReq} from "./ajax_req";
import {isBrave} from "./client_detection";

//https://docs.everyaction.com/reference/cookie-consent-management

const cc_cookie = 'edf_cc',
    cookies_rejected = 'cookies_rejected',
    cookies_accepted = 'cookies_accepted',
    cookies_changed = 'cookies_changed',
    from_edf_cc = 'from=edf_cc'

let cached_cookie_consent = null,
    cookies_were_rejected = false,
    privacy_policy = 'https://'+(config.isDev?'dv8':'www')+'.edf.org/privacy-statement';

if (config.isNZA){
    privacy_policy = '/privacy-policy/';
}

let description = `We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners. You can accept all cookies, or reject all but those that are necessary. See our <a href="${privacy_policy}?${from_edf_cc}">privacy policy</a>.`;

export function reset(){
    cookies_were_rejected = false;
    cached_cookie_consent = null
    eraseCookie(cc_cookie);
    allowCookies();
    eraseCookie(cc_cookie);
    window.location.reload();
}

export function injectResetOption(){
    let id = 'cookieconsent_reset';

    addReadyEvent(function(){
        appendHTML({
            a: $name('body'),
            elem: 'button',
            id: id,
            className: 'button',
            style: 'position: fixed; bottom: 5px; right: 5px; z-index: 999; font-size: 10px; cursor: pointer;',
            innerText: 'Reset cookie consent'
        });

        addEvent(id, 'click', reset);
    });
}

export function getConsentCookie() {
    if (cached_cookie_consent){
        return cached_cookie_consent;
    }

    let existing_cookie = getCookies()[cc_cookie]; //WARN: avoid using readCookie in this place due to max callStack and rangeErrors
    if (existing_cookie){
        try {
            existing_cookie = JSON.parse(existing_cookie);

            if (!existing_cookie.categories || !Array.isArray(existing_cookie.categories) || !existing_cookie.consent_date || !existing_cookie.consent_uuid){
                existing_cookie = null;
            }
            else {
                cached_cookie_consent = {...existing_cookie};
            }
        } catch(e){
            existing_cookie = null;
        }
    }

    return existing_cookie;
}

export function didUserRejectConsent(){
    let existing_cookie = getConsentCookie();

    if (!existing_cookie){
        return false;
    }

    cookies_were_rejected = existing_cookie.categories.length === 1 && existing_cookie.categories[0] === 'necessary';

    return cookies_were_rejected;
}

export function hasConsentCookie(){
    let cookie = getConsentCookie();

    if (!cookie){
        cached_cookie_consent = null;
        eraseCookie(cc_cookie);
    }

    return !!cookie;
}

export function handleCookieRejection(block_event){
    cookies_were_rejected = true;
    blockCookies(cookie_consent_cookies);
    overrideAppendChild();
    overrideInsertBefore();

    if (!block_event){
        fireCustomEvent(cookies_rejected);
        fireCustomEvent(cookies_changed, {rejected: true, accepted: false});
    }

    if (window.MutationObserver && config.isBlogs){
        let observer = new MutationObserver(mutations => {
            mutations.forEach(({ addedNodes }) => {
                addedNodes.forEach(node => {
                    if (node.nodeType === 1 && node.tagName === 'SCRIPT') {
                        let src = (node.src || '').toLowerCase(),
                            evt_type = 'beforescriptexecute';

                        if (src.indexOf('sharethis') > -1){
                            console.warn('blocking '+src);

                            node.type = config.javascript_blocked_for_consent;

                            let beforeScriptExecuteListener = function(event) {
                                if (node.getAttribute('type') === config.javascript_blocked_for_consent){
                                    event.preventDefault();
                                }

                                node.removeEventListener(evt_type, beforeScriptExecuteListener);
                            }

                            node.addEventListener(evt_type, beforeScriptExecuteListener);
                        }
                    }
                });
            });
        });

        observer.observe(document.documentElement, {
            childList: true,
            subtree: true
        });
    }

    //https://www.cookieyes.com/documentation/features/integrations/facebook-pixel-consent-mode/
    if (window.fbq){
        fbq('consent', 'revoke');
    }
}

export function handleCookieAcceptance(){
    fireCustomEvent(cookies_accepted);
    fireCustomEvent(cookies_changed, {rejected: false, accepted: true});

    //https://www.cookieyes.com/documentation/features/integrations/facebook-pixel-consent-mode/
    if (window.fbq){
        fbq('consent', 'grant');
    }

    forAll('script[type="'+config.javascript_blocked_for_consent+'"]', function(e){
        loadScript(e.src);
    });
}

export function init(){
    if (config.isDev && config.isTesting){
        //currently will only fire when document.cookie is being intercepted
        document.addEventListener('cookiechange', ({detail: {oldValue, newValue}})=> {
            console.log(`Cookie changed from "${oldValue}" to "${newValue}"`);
        });

        listenCookieChange(({oldValue, newValue})=> {
            console.log(`Cookie changed from "${oldValue}" to "${newValue}"`);
        });

        [cookies_accepted, cookies_changed, cookies_rejected].forEach(function(t){
            addEvent(t, function(e){
                console.log(t, e.detail);
            });
        });
    }

    if (hasConsentCookie()){
        if (config.isDev && config.isTesting){
            injectResetOption();
        }

        if (didUserRejectConsent()){
            return handleCookieRejection();
        }

        return handleCookieAcceptance();
    }

    if (config.isGDPR || config.isIntl){
        handleCookieRejection(true);
    }

    let cdn = 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.9.1/dist/cookieconsent.';
    _LS('cookie_consent');

    loadAssets([cdn+'css', isBrave ? config.utility_path+'cc.js' : cdn+'js'], function(){
        let edf_cc_copy = 'edf_cc_copy';
        let description_override = window[edf_cc_copy] || ($id('#'+edf_cc_copy) || {}).innerHTML;

        if (typeof description_override === 'string'){
            description_override = description_override.trim();

            if (description_override && description_override.indexOf('</a>') > -1){ //requires privacy policy link included. NOTE: doesn't actually validate the link is working privacy policy
                if (description_override.indexOf(from_edf_cc) === -1){
                    description_override = description_override.replace('">', '?'+from_edf_cc+'">');
                }

                description = description_override;
            }
        }

        let cc = initCookieConsent(),
            gup_mode = (gup('cookieconsent_mode')+'').toLowerCase(),
            mode = 'opt-out',
            allow_preferences = gup('cookieconsent_preferences') === 'true';

        if (config.isDev && ['opt-out', 'opt-in'].indexOf(gup_mode) > -1){
            mode = gup_mode;
        }

        //https://github.com/orestbida/cookieconsent#apis--configuration-parameters
        cc.run({
            autorun: true,
            mode: mode,
            current_lang: 'en',
            //autoclear_cookies: true,                    // default: false
            cookie_name: cc_cookie,             // default: 'cc_cookie'
            cookie_expiration: 365,                     // default: 182
            cookie_domain: config.isDevHosting ? config.HN : getParentDomain(),
            // page_scripts: false,                         // default: false
            // force_consent: true,                        // default: false

            // auto_language: null,                     // default: null; could also be 'browser' or 'document'
            // autorun: true,                           // default: true
            // delay: 0,                                // default: 0
            // hide_from_bots: false,                   // default: false
            // remove_cookie_tables: false              // default: false
            // cookie_domain: location.hostname,        // default: current domain
            // cookie_path: '/',                        // default: root
            cookie_same_site: 'None',
            // use_rfc_cookie: false,                   // default: false
            // revision: 0,                             // default: 0

            gui_options: {
                consent_modal: {
                    layout: 'cloud',                    // box,cloud,bar
                    position: 'bottom right',          // bottom,middle,top + left,right,center
                    transition: 'slide'                 // zoom,slide
                },
                settings_modal: {
                    layout: 'box',                      // box,bar
                    // position: 'left',                   // right,left (available only if bar layout selected)
                    transition: 'slide'                 // zoom,slide
                }
            },

            languages: {
                en: {
                    consent_modal: {
                        // title: '',
                        description: description,
                        primary_btn: {
                            text: 'Accept',
                            role: 'accept_all' //'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: allow_preferences ? 'Preferences' : 'Reject',
                            role: allow_preferences ? 'settings' : 'accept_necessary'
                        },
                        // revision_message: '<br><br> Dear user, terms and conditions have changed since the last time you visited!'
                    },
                    settings_modal: {
                        title: 'Cookie settings',
                        save_settings_btn: 'Save current selection',
                        accept_all_btn: 'Accept all',
                        reject_all_btn: 'Reject all',
                        close_btn_label: 'Close',
                        cookie_table_headers: [
                            {col1: 'Name'},
                            {col2: 'Domain'},
                            {col3: 'Expiration'}
                        ],
                        blocks: [
                            {
                                title: 'Cookie usage',
                                description: 'LOREM_IPSUM' + ' <a href="#" class="cc-link">Privacy Policy</a>.'
                            },
                            {
                                title: 'Strictly necessary cookies',
                                description: 'LOREM_IPSUM' + 'LOREM_IPSUM' + "<br><br>" + 'LOREM_IPSUM' + 'LOREM_IPSUM',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true  //cookie categories with readonly=true are all treated as "necessary cookies"
                                }
                            },
                            {
                                title: 'Site personalization cookies',
                                description: 'LOREM_IPSUM' + 'LOREM_IPSUM' + "<br><br>" + 'LOREM_IPSUM' + 'LOREM_IPSUM',
                                toggle: {
                                    value: 'personalization',
                                    enabled: false,
                                    readonly: false
                                }
                            },
                            {
                                title: 'Analytics & Performance cookies',
                                description: 'LOREM_IPSUM',
                                toggle: {
                                    value: 'analytics',
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [
                                    {
                                        col1: '^_ga',
                                        col2: 'yourdomain.com',
                                        col3: 'description ...',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'yourdomain.com',
                                        col3: 'description ...',
                                    },
                                    {
                                        col1: 'cc_youtube',
                                        col2: 'yourdomain.com',
                                        col3: 'Cookie set by iframemanager'
                                    }
                                ]
                            }, {
                                title: 'More information',
                                description: 'LOREM_IPSUM' + ' <a class="cc-link" href="">Contact me</a>.',
                            }
                        ]
                    }
                }
            },

            onFirstAction: function (user_preferences, cookie) {
                cached_cookie_consent = null;

                if (user_preferences.accept_type === 'necessary'){
                    handleCookieRejection();

                    ajaxReq({
                        method: 'POST',
                        url: config.utility_path + 'consent/rejection/'+cookie.consent_uuid,
                        withCredentials: true,
                        data: cookie,
                        format: 'json',
                        body_format: 'json',
                    });
                }

                setTimeout(function(){
                    GAEvent('consent', {
                        at: 'cookie_consent',
                        af: user_preferences.accept_type,
                        el: user_preferences.accepted_categories.join(',')
                    });

                    dataLayer.push({
                        event: 'consent',
                        at: 'cookie_consent',
                        af: 'on_first_action',
                        el: user_preferences.accept_type,
                        ...user_preferences,
                        ...cookie
                    });
                }, 100);

                if (config.isDev){
                    injectResetOption();
                }
            },

            onAccept: function (cookie) {
                cached_cookie_consent = null;

                handleCookieAcceptance();

                dataLayer.push({
                    event: 'consent',
                    at: 'cookie_consent',
                    af: 'on_accept',
                    ...cookie
                });

                // If analytics category is disabled => load all iframes automatically
                if (cc.allowedCategory('analytics')) {
                    // console.log('iframemanager: loading all iframes');
                }
            },

            onChange: function (cookie, changed_preferences) {
                cached_cookie_consent = null;

                // If analytics category is disabled => ask for permission to load iframes
                if (!cc.allowedCategory('analytics')) {
                    // console.log('iframemanager: disabling all iframes');
                }
                else {
                    // console.log('iframemanager: loading all iframes');
                }
            }
        });

        GAEvent('display', {
            at: 'cookie_consent'
        });
    });
}

export {
    cookies_were_rejected
}