import { config } from "./config";
import {_ef, copyObj, gti, onArrayPush, uuid} from "./helpers";
import * as ut from "./ut";
import {LS_G, readCookie, SS_G} from "./storage";
import * as Base64 from "./base64";
import {Sentry} from "./sentry";
import {CrossDomainStorage} from "./cross_domain_storage";

export function checkRemoteStorage(key, cb){
    cb = cb || _ef;

    let remoteStorage = new CrossDomainStorage('https://' + config.topDomain + '.edf.org', '/assets/global/ut.html?gti='+gti());
    remoteStorage.requestValue(key, function(key, value){
        cb(key, value);
    });
}

export function push(event){
    dataLayer.push(event);
}

let start = config.pageStartTime,
    existing_data_object = {},
    existing_data_layer = [],
    edf_dl = 'edf_datalayer',
    storage_dl = LS_G(edf_dl) || SS_G(edf_dl) || null,
    cookie_dl = readCookie(edf_dl),
    resurrected_dl = {
        page: {},
        session: {},
        user: {}
    };

if (storage_dl){
    try {
        storage_dl = Base64.decode(storage_dl);
        storage_dl = JSON.parse(storage_dl);
    } catch(e){
        Sentry.captureException(e);
    }
}

if (cookie_dl){
    try {
        cookie_dl = Base64.decode(cookie_dl);
        cookie_dl = JSON.parse(cookie_dl);

        let map = {
            page: {
                z: 'last_page_id'
            },
            session: {
                l: 'last',
                s: 'start',
                i: 'id',
                p: 'page',
                n: 'pages',
                w: 'webteam_traffic',
                e: 'email_traffic',
                f: 'marketing_traffic',
                wc: 'wave_code',
                isc: 'internal_source_code',
                cc: 'contact_channel',
                u: 'utm_id',
                pi: 'persona_id',
                pg: 'persona_group'
            },
            user: {
                t: 'temp_id',
                c: 'cons_id',
                a: 'adestra_contact_id',
                b: 'p2a_advocate_id',
                m: 'classy_member_id',
                y: 'classy_supporter_id',
                d: 'device_id',
                v: 'variant_id'
            }
        };

        for (let entity in map){
            cookie_dl[entity] = cookie_dl[entity] || {};

            for (let key in map[entity]){
                cookie_dl[entity][map[entity][key]] = cookie_dl[key];
                delete cookie_dl[key];
            }
        }
    } catch(e){
        Sentry.captureException(e);
    }
}

if (storage_dl && typeof storage_dl === 'object'){
    ['user', 'session', 'page'].forEach(function(entity){
        resurrected_dl[entity] = Object.assign(resurrected_dl[entity], copyObj(storage_dl[entity] || {}));
    });
}

if (cookie_dl && typeof cookie_dl === 'object'){
    ['user', 'session', 'page'].forEach(function(entity){
        resurrected_dl[entity] = Object.assign(resurrected_dl[entity], copyObj(cookie_dl[entity] || {}));
    });
}

if (config.isJobs && readCookie('temp_id') === 't1657201413212') { //?
    console.log('checking whether asnyc: 1');

    checkRemoteStorage(edf_dl, function(value){
        console.log('checking whether asnyc: 2');
        console.log(value);

        try {
            value = Base64.decode(value);
            value = JSON.parse(value);
        } catch(e){
            Sentry.captureException(e);
        }

        console.log(value);
    });

    console.log('checking whether asnyc: 3');
}

//CAUTION: might drop some data if only picking up first item in existing DL
if (Array.isArray(window.dataLayer) && window.dataLayer.length){
    if (window.dataLayer[0] && typeof window.dataLayer[0] === 'object' && (window.dataLayer[0].event+'').indexOf('gtm') === -1){
        existing_data_object = copyObj(window.dataLayer[0]);
    }

    existing_data_layer = copyObj(window.dataLayer);
}

let dataLayer = [{
    page: {
        start: start,
        ga: config.runGA,
        id: uuid(),
        events: 0,
        goals: 0
    },
    session: {},
    user: {},
    isDev: config.isDev,
    isTesting: config.isTesting,
    ...existing_data_object
}, ...existing_data_layer];

for (let prop in config){
    if (prop.indexOf('is') === 0){
        dataLayer[0][prop] = config[prop];
    }
}

let namespace = (config.isClassy || config.isGP ? 'edf_' : '')+'dataLayer';

if (!Array.isArray(window[namespace])){
    window[namespace] = copyObj(dataLayer);
}
else {
    window[namespace].unshift(copyObj(dataLayer[0]));
}

onArrayPush(dataLayer, function(item){
    if (window[namespace].length){
        window[namespace][0] = dataLayer[0];
    }

    if (item && typeof item === 'object' && !item.pushed_from_window){
        item.pushed_from_ut = true;
    }

    if (!item || typeof item !== 'object' || !item.pushed_from_window){
        // if (item.toString() === '[object Arguments]' && item.length > 2 && item[0] === 'event' && typeof item[1] === 'string' && typeof item[2] === 'object'){
        //     let last_entry = window[namespace][window[namespace].length-1];
        //     if (last_entry.toString() === '[object Arguments]' && last_entry.length > 2 && last_entry[0] === 'event' && last_entry[1] === item[1] && typeof last_entry[2] === 'object'){
        //         let new_entry = copyObj(item[2]);
        //         last_entry = copyObj(last_entry[2]);
        //         delete last_entry.uid;
        //
        //         if (JSON.stringify(new_entry) === JSON.stringify(last_entry)){
        //             return;
        //         }
        //     }
        // }

        window[namespace].push(item);
    }

    item.uid = uuid();
    ut.push(item);
});

//pushes events on namespace to $EDF.dataLayer
onArrayPush(window[namespace], function(item){
    if (item && typeof item === 'object' && !item.pushed_from_ut){
        item.pushed_from_window = true;

        window[namespace][0] = dataLayer[0];
        item.uid = uuid();
        dataLayer.push(item);
    }
});

export {
    resurrected_dl,
    dataLayer,
    namespace
}