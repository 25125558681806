import { config } from "./config";
import { dataLayer } from "./datalayer";
import {removeEPs, _ef, gti} from "./helpers";
import { loadScript } from "./load_assets";
// import {isBrave} from "./client_detection";

let Sentry = window.Sentry || {
        captureMessage: _ef,
        captureException: _ef,
        configureScope: _ef,
        addBreadcrumb: _ef,
        fake: true,
        Severity: {
            Info: 'Info'
        }
    },
    SentryLoaded,
    SentryEvents = 0,
    SentryExceptions = [];

function onSentryLoad(cb){
    if (SentryLoaded){
        return cb();
    }

    let cycles = 0;

    let wait = setInterval(function(){
        if (SentryLoaded){
            clearInterval(wait);
            cb();
        }
        else if (cycles > 2000){
            clearInterval(wait);
        }

        cycles++;
    }, 5);
}

export function setSentryUser(obj){
    onSentryLoad(function(){
        Sentry.configureScope(function(scope) {
            scope.setUser(obj);
        });
    });
}

export function setSentryTag(key, val){
    onSentryLoad(function(){
        Sentry.configureScope(function(scope) {
            scope.setTag(key, val);
        });
    });
}

export function setSentryTags(obj){
    onSentryLoad(function(){
        for (let prop in obj){
            setSentryTag(prop, obj[prop]);
        }
    });
}

export function setSentryLevel(level){
    onSentryLoad(function(){
        Sentry.configureScope(function(scope) {
            scope.setLevel(level);
        });
    });
}

export function setSentryExtra(key, val){
    onSentryLoad(function(){
        Sentry.configureScope(function(scope) {
            scope.setExtra(key, val);
        });
    });
}

export function setSentryExtras(obj){
    onSentryLoad(function(){
        for (let prop in obj){
            setSentryExtra(prop, obj[prop]);
        }
    });
}

export function sentryMessage(message, obj){
    onSentryLoad(function(){
        if (typeof obj === 'object'){
            if (obj.level){
                setSentryLevel(obj.level);
                delete obj.level;
            }

            setSentryExtras(obj);
        }

        dataLayer.push({
            ut: true,
            event: 'sentry',
            message: message
        });

        Sentry.captureMessage(message);
    });
}

export function sentryInit() {
    //browser Brave has a tendency to not load Sentry
    if (!window.Sentry || typeof window.Sentry.init !== 'function'){
        return;
    }

    Sentry = window.Sentry;

    SentryLoaded = true;

    let environment = config.isLocal ? 'local' : (config.isDev ? 'development' : 'production'),
        sampleRate = config.isClassy || config.isEA || config.isUtility || config.isTesting || config.isDev ? 1 : 0.5,
        dsn = config.isClassy ? 'https://46ee3d2e9ec84272b8159727f1764e41@sentry.edf.org/4' : 'https://3d3abceb07ac4e3a94a7ee3c94e963ba@sentry.edf.org/2';

    if (config.isEA){
        dsn = 'https://dc34beba4cb04bffb2754cf9b46b6cff@sentry.edf.org/6';
    }

    if (config.isUtility){
        dsn = 'https://60224877fd0c4afe982a40ac71248a09@sentry.edf.org/5';
        environment = config.HN === config.UTD ? 'production' :
            (config.HN === 'stage.'+config.UTD ? 'staging' : (config.HN === 'test.'+config.UTD ? 'testing' : 'development'));
    }

    Sentry.init({
        environment: environment,
        sampleRate: sampleRate,
        dsn: dsn,
        beforeSend: function(event, hint) {
            if (config.DNT){
                return null;
            }

            let originalExceptionString = 'originalException',
                originalException = '',
                originalExceptionLower = '';

            if (hint) {
                if (typeof hint[originalExceptionString] === 'object'){
                    originalException = (hint[originalExceptionString].stack || hint[originalExceptionString].message || '');
                }
                else if  (typeof hint[originalExceptionString] === 'string') {
                    originalException = hint[originalExceptionString];
                }
            }

            if (!originalException){
                originalException = event.message || '';
            }

            originalExceptionLower = originalException.toLowerCase();

            SentryExceptions.push({
                event: event,
                hint: hint,
                originalException: originalException,
                originalExceptionLower: originalExceptionLower
            });

            if (gti(config.pageStartTime) > 1000*10){
                if (originalExceptionLower.indexOf('unexpected end of input') > -1){ //likely just related to typing in browser console
                    return null;
                }
            }

            if (config.log){
                console.error(event);
                console.error(hint);
            }

            if (originalExceptionLower.indexOf('quick edit could not associate') > -1
                || originalExceptionLower.indexOf('moatmak') > -1
                || originalExceptionLower.indexOf('prepareforelementcapture') > -1
                || originalExceptionLower.indexOf('resizeobserver loop limit exceeded') > -1
            ) {
                return null;
            }

            if (config.isClassy
                && (originalExceptionLower.indexOf('[$rootscope:inprog]') > -1
                    || originalExceptionLower.indexOf('cannot read property \'dim\' of null') > -1
                    || originalExceptionLower.indexOf('directives/scflowmodal/scflowmodalservice.js') > -1
                    || originalExceptionLower.indexOf('services/scauth/scauth') > -1)
            ){
                return null;
            }

            /*if (event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id });
            }*/

            SentryEvents++;

            if (originalException){
                dataLayer.push({
                    ut: true,
                    event: 'exception',
                    description: originalException,
                    count: SentryEvents
                });
            }

            if (SentryEvents > 10){
                return null;
            }

            if (originalException){
                //https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions
                GAEvent('exception', {
                    description: originalException,
                    fatal: true
                });
            }

            return event; //return null to cancel event
        },
        beforeBreadcrumb: function(breadcrumb, hint) {
            // console.log(breadcrumb);
            // console.log(hint);
            return breadcrumb
        },
        denyUrls: [],
        whitelistUrls: [],
        ignoreErrors: [
            //https://github.com/getsentry/sentry-javascript/issues/3440
            //https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
            //large spikes stemming from Microsoft safe link checkers?
            "Non-Error exception captured",
            "Non-Error promise rejection captured",
            "Possible side-effect in debug-evaluate"
        ]
    });

    setSentryTags({
        hostname: config.HN,
        referrer: removeEPs(document.referrer),
        admin: config.isAdmin,
        language: (navigator.language || navigator.userLanguage).toLowerCase()
    });

    if (config.drupalUID){
        setSentryUser({uid: config.drupalUID});
    }
}

if (window.Sentry && !Sentry.fake){
    sentryInit();
}
else {
    loadScript('https://browser.sentry-cdn.com/6.13.3/bundle.min.js', sentryInit);
    // loadScript(isBrave ? config.utility_path+'sntry.js' : 'https://browser.sentry-cdn.com/6.13.3/bundle.min.js', sentryInit);
}

export {
    Sentry,
    SentryEvents,
    SentryExceptions
}