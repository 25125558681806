import { config } from './config';
import {$id, gti, is, _viewport} from "./helpers";
import {is_touch_device, isBot, supportsBeacon} from './client_detection';
import {are_cookies_enabled, createCookie, eraseCookie, LS_enabled, readCookie, SS_enabled} from "./storage";
import {$qs, hasClass} from "./dom";
import {triggerEvent, jQueryOnLoad, addEvent} from "./events";
import {GAEvent} from "./ga";
import {ajaxReq} from "./ajax_req";
import {pixeler} from "./load_assets";
import * as ut from './ut';
import {getFirstPaint} from './first_paint';

export function userEngagement($, options) {
    if (isBot) {
        return;
    }

    function seconds(s) {
        return oneDeci(s / 1000);
    }

    function oneDeci(item) {
        return parseFloat(item.toFixed(1));
    }

    function cleanFancy(text) {
        return text.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
    }

    let opts = {
        dev: false,
        unload: function () {
            return true;
        },
        startTime: gti(),
        share: 0,
        signup: 0,
        action: 0,
        comment: null,
        commentor: null,
        init_data: null,
        update_data: null,
        scrolled: 0
    };

    opts = Object.assign(opts, options);

    let endpoint = config.utility_path + 'edf/pageview',
        html = $('html'),
        win = $(window),
        body = $('body'),
        doc = $(document),
        dev = config.isDev,
        resizeTimer = null,
        resizeCount = 0,
        vizApi = false,
        vizAPIEvent = false,
        timeInvisible = 0,
        invis = 0,
        isVisible = false,
        hidden = 'hidden',
        visibilityChange = 'visibilitychange',
        vendor_prefix = ['moz', 'ms', 'webkit'],
        isTouch = is_touch_device(),
        scrolls = 0,
        scrolled = 0,
        milestones = ['Loaded'],
        milestoneTimes = [0],
        width = win.width(),
        totalTime,
        layout = isTouch ? (width > 759 ? 'Tablet' : width < 760 && width > 599 ? 'Phablet' : 'Mobile') + ': ' : (width < 1000 && width > 759 ? 'Narrow' : width <= 759 ? 'Small' : 'Wide') + ': ',
        page_was_read = null,
        scrollTimeout,
        inScrolling = false,
        movingOrScrolling = 0,
        timeSinceLastMove = 0,
        scrollPos = [],
        contentProgress = 0,
        totalclicks = 0,
        engagement_clicks = 0,
        clickData = [],
        lastClick = false,
        attention = 0,
        counter = 0,
        action,
        actionClick = 0,
        donateClick = 0,
        checker = isTouch ? 15 : 30,
        updateInt = setInterval(function () {
            counter++;
            timeSinceLastMove++;

            if (movingOrScrolling === 1) {
                if (counter % 3 === 0 && scrollPos.length < 200) {
                    scrollPos.push(parseInt(win.scrollTop()));
                }

                attention++;
            }

            if (counter % checker === 0 && timeSinceLastMove < checker) {
                update(false);
            }
        }, 1000),
        timeTimeout,
        insertId = false,
        lastUpdate = null,
        onloaded = false,
        unloaded = false,
        stopClicking = false,
        first_paint_recorded;

    function onInit() {
        invis = opts.startTime;

        body.append('<div class="adsbox"></div>');

        setTimeout(function () {
            let acid = readCookie('acid'),
                temp = readCookie('temp_id'),
                screen = window.screen,
                loc = location,
                cookies = are_cookies_enabled(),
                dH = doc.height(),
                wH = win.height(),
                ref = document.referrer,
                ads = $('.adsbox'),
                data = {
                    start_time: opts.startTime,
                    nid: ut.getPage('nid'),
                    status: ut.getPage('page_status'),
                    user_id: acid ? acid : (temp ? temp : '0'), //ideally update to collect all user ids
                    url: config.LN + '//' + config.HN + config.PN,
                    querystring: loc.search + loc.hash,
                    referrer: ref === '' ? null : ref,
                    src: ut.getSess('source') || null,
                    subsrc: ut.getSess('sub_source') || null,
                    utm_id: ut.getSess('utm_id') || null,
                    utm_id_collection: ut.getSess('utm_id_collection') || null,
                    cookies_enabled: cookies,
                    cookies_length: cookies && !isNaN(document.cookie.length) ? document.cookie.length : '',
                    localstorage: LS_enabled,
                    sessionstorage: SS_enabled,
                    beacon: supportsBeacon() ? 1 : 0,
                    is_touch_device: isTouch,
                    screen_width: win.width(),
                    screen_height: wH,
                    resolution_width: screen.availWidth,
                    resolution_height: screen.availHeight,
                    document_width: doc.width(),
                    document_height: dH,
                    baseline: parseInt(wH * 100 / dH),
                    timing_api_exists: 'performance' in window && 'timing' in window.performance,
                    viz_api_exists: vizApi,
                    adblock: ads.height() === 0 ? 0 : 1,
                    doNotTrack: config.DNT
                };

            let row = dataLayer[0];
            if (row.personaID && row.personaID !== 'false') {
                data.persona_trigger = row.personaID;
            }

            if (row.persona && row.persona !== 'false') {
                data.persona = row.persona;
            }

            data.cid = ut.getUser('ga_client_id');

            ads.remove();

            if (data.timing_api_exists) {
                let per = window.performance,
                    nav = 'navigation',
                    t = per.timing,
                    n = t.navigationStart,
                    c = t.domComplete,
                    r = t.responseStart,
                    oe = t.loadEventEnd;

                data.redirect_time = t.redirectEnd - t.redirectStart;
                data.dns_time = t.domainLookupEnd - t.domainLookupStart;
                data.tcp_time = t.connectEnd - t.connectStart;
                data.request_time = r - t.requestStart;
                data.response_time = t.responseEnd - r;
                data.latency = r - t.connectStart;

                let paint = getFirstPaint();
                if (paint) {
                    data.first_paint = paint;
                    first_paint_recorded = true;
                }

                if (c && oe) {
                    onloaded = true;
                    data.processing_time = c - t.domLoading;
                    data.total_dom_time = c - n;
                    data.onload_time = oe - t.loadEventStart;
                    data.total_load_time = oe - n;
                }

                if (nav in per) {
                    data[nav] = per[nav].type;
                }
            }

            opts.init_data = data;

            if (!('withCredentials' in new XMLHttpRequest())) {
                GAEvent('exception', {
                    ec: 'engagement',
                    description: 'lacking withCredentials'
                });
            }

            if (!options.bypass_utility && (!config.isAdmin || config.isTesting)) {
                ajaxReq({
                    method: 'POST',
                    url: endpoint + '/load',
                    data: data,
                    format: 'json',
                    body_format: 'json',
                    withCredentials: true
                }, function (err, json) {
                    if (err || json.error) {
                        return GAEvent('exception', {
                            ec: 'engagement',
                            el: 'update_fail',
                            description: JSON.stringify(err || json.error)
                        });
                    }

                    insertId = json.uid;

                    ut.setPage('engagement_page_id', json.uid);
                    ut.setUser('edf_uuid', json.uuid);
                });
            }

            // if (!opts.init_data.logged_in) {
            //     let comment = $('.comment-form');
            //     if (comment.length) {
            //         comment.submit(function () {
            //             let valid = true,
            //                 text,
            //                 submitter = {},
            //                 form = $(this);
            //
            //             form.find('input[type="text"], textarea').each(function () {
            //                 let el = $(this);
            //                 if (valid && el.attr('id').indexOf('mollom') === -1) {
            //                     let val = el.val();
            //                     if (val === '') {
            //                         valid = false;
            //                     }
            //                     else if (el.prop('tagName') === 'TEXTAREA') {
            //                         text = el.val();
            //                     }
            //                     else {
            //                         let temp = el.attr('name');
            //                         if (temp === 'mail' && !(val.indexOf('@') > 0 && val.lastIndexOf('.') > val.indexOf('@') && val.length > val.lastIndexOf('.') + 1)) {
            //                             valid = false;
            //                         }
            //                         else {
            //                             submitter[temp] = val;
            //                         }
            //                     }
            //                 }
            //             });
            //
            //             opts.comment = valid && text ? cleanFancy(text.slice(0, 120)) : null;
            //             opts.commentor = valid ? submitter : null
            //         });
            //     }
            // }

            eraseCookie('EDFEngage');
        }, 500);
    }

    function calculateEngagement(){
        let click_points = engagement_clicks > 0 ? (5 * Math.pow(engagement_clicks, 2) - 4 * engagement_clicks + 3) / (Math.pow(engagement_clicks, 2) + 3 * engagement_clicks) : 0,
            attention_points = attention >= 5 ? (attention - 5) / 26.666667 > 7.5 ? 7.5 : (attention - 5) / 26.666667 : 0,
            action_points = (opts.signup + opts.action + opts.share + (opts.comment !== null ? 1 : 0)) * 1.5;

        if (click_points > 5) {
            click_points = 5;
        }
        if (action_points > 7.5) {
            action_points = 7.5;
        }

        return parseFloat((click_points + attention_points + action_points).toFixed(2))
    }

    function calculateAttentiveness(){
        let attentive = parseInt(attention * 100 * 1000 / gti(opts.startTime));
        return attentive <= 100 ? attentive : 100;
    }

    function getContentHeight(){
        let bottom = opts.content_bottom.height(),
            bottomHeight = bottom !== null ? bottom : 0;

        return opts.content_top.height() - bottomHeight;
    }

    function calculateContentProgress(){
        let position = win.scrollTop() + win.height() - opts.content_top.position().top,
            progress = parseInt(position * 100 / getContentHeight());

        progress = contentProgress > progress ? contentProgress : progress;

        return progress > 100 ? 100 : progress;
    }

    function update(unloading) {
        if (unloaded) {
            return;
        }

        if (unloading) {
            unloaded = true;
        }

        if (unloading && vizApi && !isVisible) {
            let invisTime = gti() - invis;
            timeInvisible += invisTime > 0 ? invisTime : 0;
        }

        let data = scrollTracking(),
            scroll = {
                uid: insertId,
                time_on_page: seconds(totalTime),
                signup_on_page: opts.signup,
                action_on_page: opts.action,
                layout: layout.replace(': ', ''),
                screen_width: win.width(),
                screen_height: win.height(),
                document_width: doc.width(),
                document_height: doc.height(),
                resizes: resizeCount,
                viz_event: vizApi ? vizAPIEvent : null,
                time_invisible: (vizApi ? seconds(timeInvisible) : null),
                time_visibile: (vizApi ? seconds(totalTime-timeInvisible) : null),
                attention: attention,
                percent_attentive: calculateAttentiveness(),
                clicks: totalclicks,
                engagement_clicks: engagement_clicks,
                last_click: lastClick || null,
                click_data: clickData.slice(0,25),
                shares: opts.share,
                baseline: data[1],
                scroll_top: is(data[4].Top, null),
                scroll_middle: is(data[4].Middle, null),
                scroll_bottom: is(data[4].Bottom, null),
                scroll_end: is(data[4].End, null),
                scroll_positions: scrollPos,
                depth: scrolled,
                thresholds: data[0],
                content_progress: calculateContentProgress(),
                page_was_read: page_was_read === null ? 0 : page_was_read,
                scroll_metric: data[2],
                page_unloaded: unloading,
                engagement: calculateEngagement(),
                comment: opts.comment,
                commentor: opts.commentor,
                clicked_to_act: actionClick,
                clicked_to_donate: donateClick
            };

        if (!onloaded && 'performance' in window && 'timing' in window.performance) {
            let per = window.performance,
                t = per.timing,
                n = t.navigationStart,
                c = t.domComplete,
                oe = t.loadEventEnd;

            if (!first_paint_recorded) {
                let paint = getFirstPaint();
                if (paint) {
                    scroll.first_paint = paint;
                    first_paint_recorded = true;
                }
            }

            if (c && oe) {
                onloaded = true;
                scroll.processing_time = c - t.domLoading;
                scroll.total_dom_time = c - n;
                scroll.onload_time = oe - t.loadEventStart;
                scroll.total_load_time = oe - n;
            }
        }

        opts.update_data = {};
        opts.update_data.scroll = scroll;
        opts.update_data.data = data;
        opts.update_data.action = action;
        opts.update_data.totalTime = totalTime;

        if (unloading) {
            unLoad(opts.update_data);
        }

        if (lastUpdate !== null) {
            for (let item in lastUpdate) {
                if (item !== 'uid' && lastUpdate[item] === scroll[item]) {
                    delete scroll[item];
                }
            }
        }

        for (let prop in scroll) {
            if (scroll[prop] === null || scroll[prop] === 0) {
                delete scroll[prop];
            }
        }

        if (insertId && !options.bypass_utility && (!config.isAdmin || config.isTesting)) {
            if (unloading) {
                if (!supportsBeacon()) {
                    return pixeler({url: endpoint + '/unload', data: scroll});
                }

                let queued;

                try {
                    queued = navigator.sendBeacon(endpoint + '/unload', JSON.stringify(scroll));
                } catch(e){}

                if (!queued) {
                    pixeler({url: endpoint + '/unload', data: scroll});
                }

                return;
            }

            ajaxReq({
                method: 'POST',
                url: endpoint + '/update',
                data: scroll,
                format: 'json',
                body_format: 'json',
                withCredentials: true
            }, function (err, json) {
                if (err || json.error) {
                    lastUpdate = null;

                    return GAEvent('exception', {
                        ec: 'engagement',
                        el: 'update_fail',
                        description: JSON.stringify(err || json.error)
                    });
                }

                ut.setUser('edf_uuid', json.uuid);
            });
        }

        lastUpdate = {};
        for (let prop in scroll) {
            lastUpdate[prop] = scroll[prop];
        }
    }

    function scrollTracking() {
        totalTime = gti(opts.startTime);
        totalTime = totalTime >= 1 ? totalTime : 1;
        action = layout;

        let data = [],
            load = 'Loaded',
            top = 'Top',
            middle = 'Middle',
            bottom = 'Bottom';

        for (let z = 0; z < milestones.length; z++) {
            action += milestones[z] + (z < milestones.length - 1 ? ', ' : '');
        }

        try {
            if (
                (action.indexOf(load) > -1 &&
                    action.match(/Loaded/g).length > 1) ||
                (action.indexOf(top) > -1 &&
                    action.match(/Top/g).length > 1) ||
                (action.indexOf(middle) > -1 &&
                    action.match(/Middle/g).length > 1) ||
                (action.indexOf(bottom) > -1 &&
                    action.match(/Bottom/g).length > 1) ||
                (action.indexOf('End') > -1 &&
                    action.match(/End/g).length > 1)
            ) {
                action = layout;

                switch (milestones.length) {
                    case 1:
                        action += load;
                        break;
                    case 2:
                        action += load + ', ' + top;
                        break;
                    case 3:
                        action += load + ', ' + top + ', ' + middle;
                        break;
                    case 4:
                        action += load + ', ' + top + ', ' + middle + ', ' + bottom;
                        break;
                    case 5:
                        action += load + ', ' + top + ', ' + middle + ', ' + bottom + ', End';
                }
            }

            let h = win.height(),
                x = doc.height(),
                base = parseInt(h * 100 / x),
                label = 'Baseline: ' + base.toString() + '%; Screen: ' + parseInt(win.width()).toString() + 'x' + parseInt(h).toString() + '; Doc: ' + parseInt(x).toString(),
                labels = action.slice(action.indexOf(':') + 1).replace(/ /g, '').split(','),
                sm = (scrolled ? scrolled : 0) / base,
                times = {};

            data.push(labels.length, base, base !== 0 && !isNaN(parseInt(sm)) ? oneDeci(sm) : null);

            label += '; Time: ' + totalTime.toString() + 'ms; Times: [';

            for (let y = 0; y < milestones.length; y++) {
                let t = milestoneTimes[y];
                label += t.toString() + (y !== l - 1 ? ', ' : ']');
                times[labels[y]] = t;
            }

            data.push(label, times);
        } catch (e) {
            data = [null, null, null, null, false];
        }

        return data;
    }

    function onScroll() {
        moving(false);

        inScrolling = true;
        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(function () {
            inScrolling = false;
        }, 150);

        let triggerTop = opts.content_top.position().top,
            contentHeight = opts.content_top.height(),
            triggerMiddle = triggerTop + contentHeight / 2,
            triggerBottom = triggerTop + contentHeight - 30,
            time = gti(opts.startTime),
            height = win.height(),
            docHeight = doc.height(),
            length = milestones.length,
            scrollTop = win.scrollTop(),
            scrollDepth = parseInt((scrollTop + height) / docHeight * 100);

        if (scrollTop + height > triggerTop) {
            contentProgress = calculateContentProgress();
            if (page_was_read === null && contentProgress > 90) {
                page_was_read = time > getContentHeight() / 1000 * 30 ? 1 : 0;
            }
        }

        if (scrollDepth > scrolls) {
            scrolls = scrollDepth;
            scrolled = scrolls <= 100 ? scrolls : 100;

            opts.scrolled = scrolled;
        }

        triggerEvent('engagementScroll', {
            scrolled: scrolled,
            scrollDepth: scrollDepth
        });

        if (length < 5) {
            if (scrollTop >= triggerTop && length === 1) {
                milestones.push('Top');
                milestoneTimes.push(time);
            }
            else if (scrollTop + height / 2 >= triggerMiddle && length === 2) {
                milestones.push('Middle');
                milestoneTimes.push(time);
            }
            else if (scrollTop + height >= triggerBottom && length === 3) {
                milestones.push('Bottom');
                milestoneTimes.push(time);
            }
            else if (scrollTop + height >= docHeight && length === 4) {
                milestones.push('End');
                milestoneTimes.push(time);
            }
        }
    }

    function moving(wipe) {
        function clear() {
            movingOrScrolling = 0;
        }

        clearTimeout(timeTimeout);

        if (wipe) {
            if (movingOrScrolling === 1) {
                clear();
            }
        } else {
            movingOrScrolling = 1;
            timeSinceLastMove = 0;

            timeTimeout = setTimeout(clear, 5000);
        }
    }

    function clicking(e) {
        if (!stopClicking) {
            stopClicking = true;

            setTimeout(function () {
                stopClicking = false;
            }, 333);

            if (!inScrolling) {
                let v = _viewport(),
                    z = e.target,
                    localName = 'localName',
                    tagName = 'tagName',
                    placeholder = 'placeholder',
                    name = 'name',
                    value = 'value',
                    element = (localName in z ? z[localName] : tagName in z !== 'undefined' ? z[tagName] : z.nodeName).toLowerCase(),
                    orig_elem = element,
                    link = element === 'a',
                    parent = $(z).parents('a').length === 1 ? $(z).parents('a') : $(z).children('a').length === 1 ? $(z).children('a') : '';

                if ('id' in z && z.id !== '') {
                    element += '#' + z.id;
                }

                if (typeof z.className === 'string' && z.className !== '') {
                    element += '.' + z.className.replace(/ /g, '.');
                }

                if (element.indexOf('input') === 0) {
                    element += '[' + placeholder + '="' + (placeholder in z ? z[placeholder] : '') + '"][' + name + '="' + (name in z ? z[name] : '') + '"][' + value + '="' + (value in z ? z[value] : '') + '"]';
                }

                lastClick = link && typeof $(z).attr('href') !== 'undefined' ? {href: z.href, text: $(z).text()} : parent.length === 1 && typeof parent.attr('href') !== 'undefined' ? {href: parent.attr('href'), text: parent.text()} : false;

                if (lastClick) {
                    lastClick.text = cleanFancy(lastClick.text.slice(0, 100));
                }

                let target = $(z),
                    countable = false,
                    label = target.parents('label').length > 0;

                opts.allowed_parents.forEach(function(allowed_parent){
                    if (target.parents(allowed_parent).length > 0){
                        countable = true;
                    }
                });

                opts.disallowed_parents.forEach(function(disallowed_parent){
                    if (target.parents(disallowed_parent).length > 0){
                        countable = false;
                    }
                });

                if (!((orig_elem === 'label' || label))) {
                    if (countable) {
                        engagement_clicks++;
                    }

                    totalclicks++;

                    if (lastClick) {
                        if (lastClick.href.indexOf('/donate') > -1 || lastClick.href.indexOf('Donation2') > -1 || lastClick.href.indexOf('https://donate.') === 0) {
                            donateClick = 1;
                            createCookie('EDFEngage', (dev ? 'S' : 'P') + 'D_' + insertId + '_' + gti(), 0);
                        }
                        else if (lastClick.href.indexOf('/site/Advocacy') > -1 || lastClick.href.indexOf('https://act.') === 0 || lastClick.href.indexOf('https://actnow.') === 0) {
                            actionClick = 1;
                            createCookie('EDFEngage', (dev ? 'S' : 'P') + 'A_' + insertId + '_' + gti(), 0);
                        }
                    }
                }

                clickData.push({
                    x: parseInt(e.pageX),
                    y: parseInt(e.pageY),
                    h: v.height,
                    w: v.width,
                    t: gti(opts.startTime),
                    e: element
                });
            }
        }
    }

    function onResize() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(resizeFunc, 300);
        moving(false);
    }

    function resizeFunc() {
        resizeCount++;
    }

    function unLoad() {
        update(true);
    }

    function onShare() {
        opts.share++;
    }

    function onSignup() {
        opts.signup = 1;
    }

    function onAction() {
        opts.action = 1;
    }

    onInit();

    if (hidden in document) {
        vizApi = true;
    }
    else {
        for (let i=0; i<vendor_prefix.length; i++){
            let prefix = vendor_prefix[i],
                prefixHidden = prefix + 'Hidden';

            if (prefixHidden in document) {
                hidden = prefixHidden;
                visibilityChange = prefix + visibilityChange;
                vizApi = true;
            }
        }
    }

    if (vizApi) {
        isVisible = !document[hidden];
    }

    addEvent(document, visibilityChange, function (e) {
        if (!e){
            return;
        }

        let state = e.target.visibilityState;

        if (state === 'prerender'){ //check prerender http://www.js-craft.io/blog/the-page-visibility-api-in-javascript/
            isVisible = true;
            return;
        }

        if (state === 'hidden') {
            moving(true);
            vizAPIEvent = true;
            invis = gti();
            isVisible = false;
            update(); //TODO add some throttling
        }
        else if (state === 'visible') {
            moving(false);
            let invisTime = gti() - invis;
            timeInvisible += (invisTime > 0 ? invisTime : 0);
            isVisible = true;
        }
    });

    addEvent('resize', onResize);
    addEvent('scroll', onScroll, { passive: true });
    //TODO use? https://developer.mozilla.org/en-US/docs/Web/API/Document/scrollend_event

    (isTouch ? $('input[type="submit"]') : html).on('click touchstart', function (e) {
        clicking(e);
        moving(false);
    });

    html.on('mousemove keypress', function (e) {
        if (isTouch && e.type === 'mousemove') {
            clicking(e);
        }

        moving(false);
    });

    moving(false);

    opts.onShare = onShare;
    opts.onAction = onAction;
    opts.onSignup = onSignup;
    opts.unLoad = unLoad;

    opts.getData = function(){
        return {
            click_data: clickData,
            total_clicks: totalclicks,
            engagement_clicks: engagement_clicks,
            last_click: lastClick || null,
            attention: attention,
            percent_attentive: calculateAttentiveness(),
            scroll_depth: scrolled,
            content_progress: calculateContentProgress(),
            engagement: calculateEngagement(),
            time_on_page: gti(opts.startTime),
            id: insertId || null,
            content_height: getContentHeight()
        }
    };

    return opts;
}

export function track_engagement(){
    if (config.DNT || isBot){
        return;
    }

    if (!(config.main_site || config.isBlogs || config.isBiz || config.isEDFCS || config.isImpact2020 || config.isImpact2021 || config.isImpact2022 || config.isImpact2023 || config.isVision2030 || config.isLocal || config.isInsider || config.isMethaneSat)){
        return;
    }

    jQueryOnLoad(function($) {
        let isShell = hasClass('body', 'page-node-type-shell'),
            isMainSiteLegacy = config.main_site && hasClass('body', 'edf'),
            isSustainabilityDashboard = config.main_site && config.PN.indexOf('/about/our-sustainability-data') === 0 && $id('page-nav');

        if (config.main_site && !isShell && !$id('content') && !$qs('main')) {
            return; //i.e. bails on something like https://www.edf.org/pa-oil-gas/
        }

        let footer = $('footer'),
            body = $('body'),
            content_top = isMainSiteLegacy ? $('#content') : $('main'),
            content_bottom = isMainSiteLegacy ? $('#appendix') : footer,
            allowed_parents = '#contentColumn, #contentBottom, .primary.col77-7, #sideColumn, #shareBottom, #edf-methane-map, #pageIntro, .sidebar.col22-2, .c-block--hero-banner, .c-block--sign-up, .slideshow__wrapper, .card__collection, .c-block__inner',
            disallowed_parents = '.subNav';

        if (config.isLocal){
            content_top = body;
            content_bottom = body;
            allowed_parents = 'body';
        }
        else if (isShell){
            content_top = $('.scenes');
            content_bottom = $('.footer');
            allowed_parents = '.scenes, .footer, #newsletter-signup';
            disallowed_parents = '#header';
        }
        else if (config.isBiz){
            content_bottom = $('#colophon');
            allowed_parents = '.videoWrapper, #primary';
            disallowed_parents = '#masthead, #sidebar-nav';
        }
        else if (config.isEDFCS){
            content_top = $('#fl-main-content');
            content_bottom = footer;
            allowed_parents = '#fl-main-content';
            disallowed_parents = 'footer';
        }
        else if (config.isImpact2020){
            content_top = $('main');
            content_bottom = $('section.end-of-page-hero');
            allowed_parents = 'main,section';
            disallowed_parents = null;
        }
        else if (config.isImpact2021){
            content_top = $('main');
            content_bottom = $('section.content-module.share-buttons');
            allowed_parents = 'main,section';
            disallowed_parents = null;
        }
        else if (config.isImpact2022){
            content_top = $('div.content');
            content_bottom = $('#a2a_overlay');
            allowed_parents = 'div.content';
            disallowed_parents = null;
        }
        else if (config.isImpact2023){ //TODO verify
            content_top = $('div.content');
            content_bottom = $('#a2a_overlay');
            allowed_parents = 'div.content';
            disallowed_parents = null;
        }
        else if (config.isVision2030){
            content_top = $('main');
            content_bottom = $('section.bottom-ctas');
            allowed_parents = 'main,section';
            disallowed_parents = null;
        }
        else if (isSustainabilityDashboard){
            content_top = $('main');
            content_bottom = $('#appendix');
            allowed_parents = 'main,section';
            disallowed_parents = null;
        }
        else if (config.isInsider){
            let main_selector = 'div[role="main"]',
                main_container_selector = '.main-container',
                main = $(main_selector);

            content_top = main.length ? main : $(main_container_selector);
            content_bottom = $('footer');
            allowed_parents = main.length ? main_selector : main_container_selector;
            disallowed_parents = null;
        }
        else if (config.isMethaneSat){
            content_top = $('.blog');
            content_bottom = $('footer');
            allowed_parents = '.blog';
            disallowed_parents = null;
        }

        if (content_top.length && content_bottom.length){
            ut.incSess('pages_with_engagement');

            window.engagement = userEngagement($, {
                unload: function(){},
                bypass_utility: config.isInsider,
                startTime: config.pageStartTime,
                content_top: content_top,
                content_bottom: content_bottom,
                allowed_parents: allowed_parents ? allowed_parents.replace(/ /g, '').split(',') : [],
                disallowed_parents: disallowed_parents ? disallowed_parents.replace(/ /g, '').split(',') : [],
                allowed_clicks: isShell ? 'info-button' : 'null'
            });
        }
    });
}