import { sentryMessage } from "./sentry";
import { addEvent } from "./events";
import * as ut from "./ut";

//https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest
export function ajaxSniffer(cb){
    let writable = false;

    //https://dmitripavlutin.com/catch-the-xmlhttp-request-in-plain-javascript/
    try {
        let openDescriptor = Object.getOwnPropertyDescriptor(XMLHttpRequest.prototype, 'open');
        //sendDescriptor = Object.getOwnPropertyDescriptor(XMLHttpRequest.prototype, 'send');

        if (openDescriptor.writable){
            writable = true;
        }
    } catch(e){}

    if (!writable){
        ut.setPage('ajax_sniffer', 'not_writable');
        sentryMessage('XMLHttpRequest.prototype.open not writable');
    }

    let origOpen = XMLHttpRequest.prototype.open;

    XMLHttpRequest.prototype.open = function() {
        let that = this,
            triggered;

        function handle(){
            if (that.readyState === 4 && typeof that.responseURL === 'string') {
                let response = that[that.responseType === 'json' ? 'response' : 'responseText'];

                if (!triggered){
                    triggered = true;
                    cb(null, that, that.responseURL, response);
                }
            }
        }

        function error(err){
            cb(err, that, that && typeof that.responseURL === 'string' ? that.responseURL : '');
        }

        addEvent(this, 'readystatechange', handle);
        addEvent(this, 'load', handle);

        addEvent(this, 'error', function(){
            error('error');
        });
        addEvent(this, 'abort', function(){
            error('abort');
        });

        return origOpen.apply(this, arguments);
    };
}