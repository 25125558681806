import { config } from "./config";
import { dataLayer, namespace } from "./datalayer";
import { gti } from "./helpers";
import {addEvent, addLoadEventWithTimeout, fireCustomEvent} from "./events";
import {acceptsCookies} from "./storage";
import {isBot} from "./client_detection";

let attempts = 0,
    url = 'https://www.googletagmanager.com/gtm.js',
    loaded = false;

function load(id, resource){

    function performLoad(){
        if (attempts > 2){
            return;
        }

        attempts++;

        if (id){
            id = (id+'').replace('GTM-', '');
        }

        id = id || '5DC6B7';

        window[namespace][0] = dataLayer[0];

        dataLayer.push({event: 'loading_gtm'});

        (function(w, d, s, l, i) {w[l] = w[l] || [];w[l].push({'gtm.start': gti(), event: 'gtm.js'});let f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = resource||(url+'?id=' + i) + dl;

            //custom edit
            j.addEventListener('load', function() {
                fireCustomEvent('gtm_loaded');
            });
            //custom edit

            f.parentNode.insertBefore(j, f);})(window, document, 'script', namespace, 'GTM-'+id);

        setTimeout(function(){
            let loaded;
            for (let i=0; i<window[namespace].length; i++){
                if (window[namespace][i].event === 'gtm.load'){
                    loaded = 1;
                }
            }

            if (!loaded){
                dataLayer.push({event: 'reloading_gtm'});
                performLoad(id, config.utility_path+'/'+id+'/_m.js'+(namespace !== 'dataLayer' ? '?l='+namespace : ''));
            }
            else {
                attempts = 0;
            }
        }, 5000);
    }

    if (isBot || config.DNT || !acceptsCookies()){ //TODO include DNT in GTM container and handle there, and drop this exclusion. Not urgent.
        return;
    }

    addLoadEventWithTimeout(performLoad, 6000);
}

function loadDefault(){
    if (isBot){
        return;
    }

    if (!config.isAdmin
        && (!config.isDev || (config.isEDF && config.isMemTool))
        && (config.main_site
            || config.isEDAF
            || config.isGP
            || (config.isEDF && config.isMemTool)
            || config.isBiz
            || config.isEDFCS
            || config.isSupplyChain
            || config.isImpact2023
            || config.isImpact2022
            || config.isImpact2021
            || config.isImpact2020
            || config.isVision2030
            || config.isElectricFleet
            || config.isFSC
            || config.isVS
            //|| config.isNZA
        )
    ) {
        load();
    }
    else if (config.isMCAFC4 || ((config.isMCAF || config.isDOF || config.isDOFC4 || config.isMRD) && config.isMemTool)){
        load('MX583C');
    }
    else if ((config.isMRD && config.HN.indexOf('recipe') > -1) || config.isEU){
        load('MX583C');
    }
    else if (config.isLocal || config.isDev){
        load('TTRBWFS');
    }
}

function reset(){
    //https://developers.google.com/gtagjs/devguide/datalayer#reset
    window[namespace].push(function() {
        this.reset();
    });
}

addEvent('gtm_loaded', function(){
    dataLayer.push({event: 'gtm_loaded_callback'});
    loaded = true;
});

let checkForLoad = setInterval(function(){
    if (typeof window.google_tag_manager === 'object' && typeof google_tag_manager.dataLayer === 'object'){
        for (let i=0; i<window[namespace].length; i++){
            if (window[namespace][i].event === 'gtm.load'){
                clearInterval(checkForLoad);
                loaded = true;
                dataLayer.push({event: 'gtm_loaded'});
            }
        }
    }

    if (!loaded && gti(config.pageStartTime) > 30000){
        clearInterval(checkForLoad);
        dataLayer.push({event: 'gtm_not_loaded'});
    }
}, 100);

export {
    load,
    loaded,
    loadDefault,
    reset,
    url
}