import { config } from "./config";
import {$id, gti, qrySep, addQryString, _ef, trim, getHourTimestamp, getExtension} from './helpers';
import { jQueryOnLoad, addEvent } from './events';
import { ajaxReq } from './ajax_req';
import {setAttrs, removeElement, appendHTML, getHead} from "./dom";
import {userTiming} from "./ga";
import {acceptsCookies} from "./storage";

export function overrideAppendChild() {
    Element.prototype.appendChild = function (elem) {
        if (arguments[0].tagName === 'SCRIPT') {
            console.log('Appending:', arguments);
            //return undefined;
        }

        return Node.prototype.appendChild.apply(this, arguments);
    };
}

export function overrideInsertBefore() {
    Element.prototype.insertBefore = function (elem) {
        if (arguments[0].tagName === 'SCRIPT') {
            console.log('Inserting:', arguments);
            //return undefined;
        }

        return Node.prototype.insertBefore.apply(this, arguments);
    };
}

export function loadScript(opts, callback) {
    if (config.pageEndTime){
        return;
    }

    function timing(){
        if (opts.timing){
            userTiming(typeof opts.timing === 'string' ? opts.timing : 'Script: '+new URL(opts.url).hostname, opts.format || 'script', gti(start));
        }
    }

    if (typeof opts === 'string') {
        opts = {url: opts, async: true};
    }

    opts.priority = opts.priority || 'auto';

    callback = callback || _ef;

    if (config.DNT && opts.url === config.CE){
        return callback();
    }

    let start = gti(),
        a = document.createElement('script');

    a.type = opts.block_for_consent && !acceptsCookies() ? config.javascript_blocked_for_consent : 'text/javascript';
    a.async = typeof opts.async === 'undefined' || opts.async;
    a.fetchpriority = opts.priority;

    if (opts.defer){
        a.defer = true;
    }

    if (opts.format === 'jsonp') {
        let name = 'jsonp_callback_' + start;
        window[name] = function(data) {
            timing();
            callback(null, data);
            removeElement($id(name))
            delete window[name];
        };

        opts.url += qrySep(opts.url) + 'callback=' + name;
        a.id = name;

        addEvent(a, 'error', function(err){
            callback(err || {error: true});
        });
    } else {
        a.onload = function(){
            timing();

            if (!opts.waitFor) {
                return callback();
            }

            let wait = setInterval(function(){
                let ready = false,
                    parts = opts.waitFor.trim().replace(/ /g, '').split('.');

                if (!parts.length || parts.length > 3){
                    ready = true;
                }
                else if (parts.length === 1){
                    if (typeof window[parts[0]] !== 'undefined'){
                        ready = true;
                    }
                }
                else if (parts.length === 2){
                    if (typeof window[parts[0]][parts[1]] !== 'undefined'){
                        ready = true;
                    }
                }
                else if (parts.length === 3){
                    if (typeof window[parts[0]][parts[1]][parts[2]] !== 'undefined'){
                        ready = true;
                    }
                }

                if (ready){
                    clearInterval(wait);
                    callback();
                }
            }, 1);
        };
    }

    if (opts.textContent) {
        a.textContent = opts.textContent;
    }
    else {
        a.src = opts.url;
    }

    getHead().appendChild(a);
}

export function loadStyle(opts, callback) {
    if (config.pageEndTime){
        return;
    }

    if (typeof opts !== 'object'){
        opts = {src: opts};
    }

    let css = document.createElement('link');

    setAttrs(css, [
        ['rel', 'stylesheet'],
        ['type', 'text/css'],
        ['href', opts.src],
        ['media', opts.media || 'all']
    ]);

    callback = callback || _ef;

    if ('onload' in css) {
        css.onload = callback;
    }
    else {
        setTimeout(callback, 500);
    }

    getHead().appendChild(css);
}

export function injectCSS(css, id) {
    if (!css){
        return;
    }

    id = id || 's'+gti()+parseInt((Math.random()*10000));

    let style = document.createElement('style');

    style.type = 'text/css';
    style.setAttribute('id', id);

    if (typeof css === 'object'){
        let css_string = '';
        for (let selector in css){
            css_string += selector+'{'+css[selector]+'}';
        }
        css = css_string;
    }

    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    }
    else {
        style.appendChild(document.createTextNode(css));
    }

    getHead().appendChild(style);

    return $id(id);
}

export function loadAssets(assets, callback) {
    if (config.pageEndTime){
        return;
    }

    callback = callback || _ef;

    let asset,
        returned = 0,
        valid = true,
        ticks = 0,
        results = [];

    if (typeof assets === 'string') {
        assets = [assets];
    }
    else if (!Array.isArray(assets)) {
        return callback(true);
    }

    for (let i = 0; i < assets.length; i++) {
        if (typeof assets[i] !== 'string') {
            valid = false;
            break;
        }

        asset = trim(assets[i]);
        assets[i] = asset;

        asset = asset.split('?')[0];

        if (asset.lastIndexOf('.css') !== asset.length - 4 && asset.lastIndexOf('.js') !== asset.length - 3 && asset.lastIndexOf('.html') !== asset.length - 5) {
            valid = false;
        }
    }

    if (!valid) {
        return callback(true);
    }

    for (let i = 0; i < assets.length; i++) {
        asset = assets[i];
        asset = asset.split('?')[0];
        if (asset.lastIndexOf('.js') === asset.length - 3) {
            loadScript(assets[i], function() {
                returned++;
            });
        }
        else if (asset.lastIndexOf('.css') === asset.length - 4) {
            loadStyle(assets[i], function() {
                returned++;
            });
        }
        else {
            ajaxReq(assets[i], function(err, html) {
                if (!err) {
                    results.push(html);
                }

                returned++;
            });
        }
    }

    let waiting = setInterval(function() {
        ticks++;

        if (assets.length === returned) {
            clearInterval(waiting);
            callback(null, results);
        }
        else if (ticks > 500 * 20) {
            clearInterval(waiting);
            callback(true);
        }
    }, 1);
}

//https://calibreapp.com/blog/priority-hints
export function appendPriorityHint(rel, asset, elem){
    elem = elem || 'link';

    let obj = {
        a: getHead(),
        elem: elem || 'link',
        rel: rel
    }

    if (elem === 'link'){
        obj.href = asset;

        //<link rel=preload> must have a valid `as` value
        //https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/preload
        if (rel === 'preload'){
            let extension = getExtension(asset),
                as_value = 'script';

            if (extension === 'css'){
                as_value = 'style';
            }
            else if (['png', 'gif', 'jpg', 'jpeg'].indexOf(extension) > -1){
                as_value = 'image';
            }
            else if (!extension){
                as_value = 'document';
            }

            obj.as = as_value;
        }
    }
    else {
        obj.src = asset;
    }

    appendHTML(obj);
}

export function preconnect(asset){
    if (typeof asset === 'string'){
        asset = [asset];
    }

    asset.forEach(function(a){
        if (a.indexOf('http') !== 0){
            a = 'https://'+a;
        }

        appendPriorityHint('preconnect', a);
    });
}

//TODO look into browser support and shim if necessary with https://blog.bitsrc.io/faster-page-loads-by-prefetching-links-during-idle-time-5abe42dacf9
export function prefetch(asset){
    appendPriorityHint('prefetch', asset);
}

export function dnsPrefetch(asset){
    appendPriorityHint('dns-prefetch', asset);
}

export function preload(asset, elem){
    appendPriorityHint('preload', asset, elem);
}

export function prerender(asset){
    appendPriorityHint('prerender', asset);
}

export function cacheBuster(){
    return Math.random() * 1e6;
}

export function pixeler(opts, i) {

    function send(){
        if (opts.dnt && config.DNT){
            return;
        }

        let is_img = typeof i === 'undefined' || !i,
            img = document.createElement(is_img ?  'img' : 'iframe');

        img.height = 1;
        img.width = 1;
        img.style.display = 'none';
        img.style.position = 'absolute';
        img.style.left = '-9999px';
        img.src = opts.url;

        if (!opts.bypass_cachebuster){
            img.src += qrySep(img.src)+'cachebuster='+cacheBuster();
        }

        if (is_img){
            img.alt = ' ';
        }

        document.body.appendChild(img);
    }

    opts = typeof opts === 'string' ? {url: opts} : opts;

    opts.url = addQryString(opts.url, opts.data);

    if (document.body) {
        return send();
    }

    let check = setInterval(function() {
        if (document.body) {
            clearInterval(check);
            send();
        }
    }, 1);
}

function asset(name, cb){
    if (config.pageEndTime){
        return;
    }

    if (typeof name === 'string'){
        name = {
            name: name
        };
    }

    cb = cb || _ef;

    let dir = name.type === 'css' ? 'css' : 'assets',
        assets_path = config.isLocal ? '/dist/'+dir+'/' : config.assets_path+'global/dist/js/'+dir+'/',
        url = assets_path+name.name+(name.type === 'css' ? cssExt() : jsExt())+'?v='+getHourTimestamp();

    if (name.type === 'css'){
        loadStyle(url, cb)
    }
    else {
        loadScript(url, cb);
    }
}

export function _LA(name, cb){
    asset(name, cb);
}

export function _LS(name, cb){
    asset({name: name, type: 'css'}, cb)
}

function ext(){
    return config.isTesting || config.isDev ? '' : '.min';
}

export function jsExt() {
    return ext() + '.js';
}

export function cssExt() {
    return ext() + '.css';
}

export function loadFancyBox(cb) {
    if (config.pageEndTime){
        return;
    }

    cb = cb || _ef;

    jQueryOnLoad(function($) {
        if ($.fancybox) {
            return cb();
        }

        let cdn = config.cdnjs+'fancybox/3.5.2/jquery.fancybox.min.';
        loadAssets([cdn+'css', cdn+'js'], function () {
            let wait = setInterval(function () {
                if ($.fancybox) {
                    clearInterval(wait);
                    $.fancybox.defaults.closeExisting = true;
                    cb();
                }
            }, 2);
        });
    });
}