import {config} from "./config";
import {GAEvent} from "./ga";
import * as lytics from "./lytics";

export function track(obj) {
    if (config.DNT){
        return false;
    }

    // track({
    //     evt: ClickEvent,
    //     component: String,
    //     parent: elementNode,
    //     element: elementNode,
    //     category: String,
    //     action: String,
    //     event_name: String, //if overriding action beyond snake-case
    //     label: String,
    //     title: String,
    //     event_type: String,
    //     event_focus: String
    // });

    if (!obj || typeof obj !== 'object' || !Object.keys(obj).length){
        // console.log(obj);
        return false;
    }

    //TODO focus vs component vs event_focus
    //705&ep.network_category=isp&ep.network_domain=comcast.net&ep.network_provider=Comcast%20Cable%20Communications%20LLC&ep.connection_type=4g&epn.ttfb=276&epn.lcp=2439&epn.fid=2&ep.event_category=Main%20site%20component&ep.event_label=Driving%20climate%20action%20and%20durable%20solutions%20at%20COP28&ep.timestamp=2023-11-28T05%3A16%3A03.165Z&ep.action_type=homepage&ep.action_focus=display_slide&epn.event_index=13&epn.time_on_page=39&epn.attention=24&epn.content_progress=14

    obj.component = obj.component || obj.component_label;
    obj.event_name = obj.event_name || obj.ga4_event_name || obj.event || obj.action;
    obj.event_focus = obj.event_focus || obj.action_focus || obj.ga_action_focus || obj.af || obj.focus || null;
    obj.event_type = obj.event_type || obj.action_type || obj.ga_action_type || obj.at || obj.type || null;
    obj.event_label = obj.event_label || obj.label || obj.el || obj.data || null;
    obj.event_category = obj.event_category || obj.category || null;

    if (!obj.event_name){
        return false;
    }

    //console.log(obj);

    if (obj.ga4 !== false){
        GAEvent(obj.event_name, {
            ec: obj.event_category,
            el: obj.event_label,
            at: obj.event_type,
            af: obj.event_focus
        });
    }

    if (obj.lytics !== false && (obj.conversion || obj.lytics)) {
        lytics.event({
            event: obj.event_name,
            event_category: obj.event_category,
            event_type: obj.event_type,
            event_focus: obj.event_focus,
            event_label: obj.event_label
        })
    }

    return true;
}