//https://docs.lytics.com/docs/lytics-javascript-tag

import {config} from "./config";
import * as ut from "./ut";
import {trimObject} from "./helpers";
import {acceptsCookies} from "./storage";
import {isBrave} from "./client_detection";

//https://docs.lytics.com/docs/onboarding-web-data
//https://github.com/lytics/pathforajs
//https://github.com/lytics/pathforajs-examples

let key = null,
    data = {},
    options = {},
    sent = [];
    //stream = 'web_'+config.HN;

export function configure(){
    if (!config.isDev || config.DNT || !acceptsCookies()){
        return;
    }

    if (config.main_site || config.isVS || config.isEA){
        key = '4ecf70726b1dac840b50b7b0d8f8f614';
    }

    if (config.isProd){
        key = 'e8b79965d9046d68aefa9cfa095ef883';
    }

    if (!key){
        return;
    }

    !function(){"use strict";var o=window.jstag||(window.jstag={}),r=[];function n(e){o[e]=function(){for(var n=arguments.length,t=new Array(n),i=0;i<n;i++)t[i]=arguments[i];r.push([e,t])}}n("send"),n("mock"),n("identify"),n("pageView"),n("unblock"),n("getid"),n("setid"),n("loadEntity"),n("getEntity"),n("on"),n("once"),n("call"),o.loadScript=function(n,t,i){var e=document.createElement("script");e.async=!0,e.src=n,e.onload=t,e.onerror=i;var o=document.getElementsByTagName("script")[0],r=o&&o.parentNode||document.head||document.body,c=o||r.lastChild;return null!=c?r.insertBefore(e,c):r.appendChild(e),this},o.init=function n(t){return this.config=t,this.loadScript(t.src,function(){if(o.init===n)throw new Error("Load error!");o.init(o.config),function(){for(var n=0;n<r.length;n++){var t=r[n][0],i=r[n][1];o[t].apply(o,i)}r=void 0}()}),this}}();

    //https://docs.lytics.com/docs/lytics-javascript-tag#configuration
    options = {
        src: isBrave ? config.utility_path+'ly.js' : 'https://c.lytics.io/api/tag/'+key+'/latest.min.js',

        blocked: false, //TODO why timing out?

        loadid: true, //By default, Lytics will store a first and third-party cookie representing a user. The loadid flag allows Lytics to use the third-party cookie if available, thus pulling cross-domain behavior into a single, unified user profile.

        //stream: stream, //do not use stream as that introduces overhead and headaches with mappings

        send: {
            pageViewOnFirst: false //CAUTION: setting false can still send an initial hit, same properties as pageview, just without `_e:pv`, if manual pageview trigger is delayed
        },

        pageAnalysis: {
            dataLayerPull: {
                disabled: false
            },
            performPageAnalysis: true
        }
    }

    if (config.isClassy){
        options.customGlobals = {
            disabled: false,
            globals: ['edf_dataLayer']
        }
    }

    let user = ut.getUser(),
        session = ut.getSess(),
        page = ut.getPage();

    let obj = {
        ga_client_id: user.ga_client_id || null,
        edf_uuid: user.edf_uuid || null,
        device_id: user.device_id || null,
        adestra_contact_id: user.adestra_contact_id || null,
        utm_id: session.utm_id || null,
        page_id: page.id || null,
        session_id: session.id || null
        // utm_source: session.utm_source || null,
        // utm_medium: session.utm_medium || null,
        // utm_campaign: session.utm_campaign || null
    };

    obj = trimObject(obj);

    data = {
        ...data,
        ...obj
    };

    jstag.init(options);

    pageview();

    jstag.getid(function(id){
        ut.setUser('lytics_uid', id);
    });

    jstag.call('entityReady', function(profile){
        console.log(profile);
    });
}

export function pageview(event){
    if (!key || !window.jstag){
        return;
    }

    event = trimObject(event || {});
    event = {...data, ...event};

    jstag.pageView(event, function(e){
       console.log(e);
       sent.push({...event});
    });
}

export function event(event){
    if (!key || !window.jstag){
        return;
    }

    event = trimObject(event);
    event = {...data, ...event};

    jstag.send(event, function(e){
        console.log(e);
        sent.push({...event});
    });
}

export function getSegments(){
    if (!key || !window.jstag){
        return;
    }

    return jstag.getSegments();
}

export function getCachedAudiences(){
    let audiences = [];

    try {
        audiences = JSON.parse(localStorage.lytics_segments);
    } catch (error) {
        console.error(error);
    }

    return audiences;
}

export function personalize(){
    //     --url 'https://api.lytics.io/api/me/fieldname/fieldval?segments=true&meta=true&download=false' \
}

export function set(obj){
    if (!key || !window.jstag){
        return;
    }

    obj = trimObject(obj);
    data = {...data, ...obj};

    jstag.send(data);
}

export function setEmail(email){
    if (!key || !window.jstag){
        return;
    }

    data = {
        ...data,
        email: email
    };

    jstag.send(data);
}

export {
    data,
    options,
    sent
}