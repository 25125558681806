import {config} from "./config";
import { dataLayer} from "./datalayer";
import * as _gtm from "./gtm";
import {acceptsCookies, LS_G, readCookie} from "./storage";
import {_LA, injectCSS, jsExt, loadScript} from "./load_assets";
import {addEvent, addReadyEvent, addLoadEvent, jQueryOnLoad, listenForEvent} from "./events";
import {formDataToJSON, trackForm, trackPlacement} from "./forms";
import {$qsAll, gti, gup, stripNonUTF, trim, download, $id, isEmail} from "./helpers";
import {GAEvent, get_ecommerce_item, virtualPage, grantStorage} from "./ga";
import {watchForLocationChanges} from "./watch_location";
import {ajaxSniffer} from "./ajax_sniffer";
import {$qs, appendHTML, forAll, waitForElement} from "./dom";
import * as ut from "./ut";
import {attachYoutube} from "./youtube";
import {DCMActivity, facebookEvent, linkedinConversion, twitter} from "./pixels";
import {createTests, experiment, testBucket} from "./testing";
import {onSubmit} from "./on_submit";
import {observeElement} from "./observe_element";
import {isMobile, isTablet} from "./client_detection";
import {trackHTML5Player} from "./html5_audio_video";
import * as classy from './classy';
import * as everyaction from "./everyaction";
import {activeP2ACampaigns} from "./p2a_feeds";
import {mobilizeEvents} from "./mobilize_feeds";

function triggerYouTubeAttach(){
    attachYoutube('iframe[src*="youtube"][src*="/embed/"]');
    attachYoutube('iframe[src*="youtube"][src*="/media/oembed"]');
}

export function onGCAAcceptsCookies(){
    grantStorage();

    if (!config.DNT){
        loadScript(config.CE);
        _gtm.load('MX583C');

        listenForEvent('EDANSignup', function(){
            twitter('o7n62', 'o892k');
        });
    }
}

export function onCMEUAcceptsCookies(){
    grantStorage();

    if (!config.DNT) {
        _gtm.load('MX583C');
    }
}

export function site_hooks(opts){
    //https://support.crazyegg.com/hc/en-us/articles/360054584474-Custom-User-Variables
    //https://support.crazyegg.com/hc/en-us/articles/1500001813622-Custom-User-Variables-Technical
    window.CE_READY = function(e){
        if (config.log){
            console.log('CE_READY');
            console.log(e);
        }
    }

    createTests();

    if (config.isClassy){
        _LA('classy_conversions');
    }

    if (config.isMethaneSat){
        _LA('methanesat');
    }

    if (config.isEA){
        //this needs to exist as import and not as a loaded asset for timing reasons
        everyaction.init();
    }

    if (config.isGCA){
        if (acceptsCookies()){
            onGCAAcceptsCookies();
        }
        else {
            // cookieStore.addEventListener('change', ({changed}) => {
            //     for (let {name, value} of changed) {
            //         console.log(`${name} was set to ${value}`);
            //     }
            // });

            let wait_for_gca = setInterval(function(){
                if (acceptsCookies()){
                    clearInterval(wait_for_gca);
                    onGCAAcceptsCookies();
                }
            }, 1000);
        }

        if (config.PN === '/'){
            forAll(document, '.slide-content--home a', function(e){
                addEvent(e, 'click', function () {
                    GAEvent('slide_click', {
                        text: e.innerText || null,
                        link: e.href || null
                    });
                });
            });
        }

        else if (config.isGCAMap){
            waitForElement('input.searchBox', function(searchbox_input){
                addEvent(searchbox_input, 'change', function(e){
                    let value = e.target.value;
                    if (value && value.trim() && stripNonUTF(value.trim())){
                        if (value){
                            GAEvent('search', {
                                ec: 'gca_air_tracker',
                                at: 'user_entered',
                                af: 'map_search_box',
                                el: stripNonUTF(value.trim())
                            });
                        }
                    }
                });
            });

        //     function trackMapClicks(){
        //         map.addListener('click', function(e) {
        //             console.log('map')
        //             console.log(e);
        //         });
        //
        //         map.addListener('contextmenu', function(e) {
        //             console.log(e);
        //         });
        //     }
        //
        //     let counter = 0;
        //     let waitForMap = setInterval(function(){
        //         counter++;
        //
        //         if (window.map || counter > 1000*20){
        //             clearInterval(waitForMap);
        //             if (window.map){
        //                 trackMapClicks();
        //             }
        //         }
        //     }, 1);
        }
    }

    if (config.isCMEU){
        if (acceptsCookies()){
            onCMEUAcceptsCookies()
        }
    }

    //DCM Floodlight activity views
    addLoadEvent(function(){
        if (config.isDev && !config.isTesting){
            return;
        }

        let page_view_category = 'PageV0';

        //CAUTION: this might be affected by conversion linker tag existing in GTM container: https://support.google.com/tagmanager/answer/7549390
        if (config.main_site && config.PN === '/'){
            DCMActivity(page_view_category, 'Homep0');
        }

        let utm_campaign = gup('utm_campaign') || '';
        if (utm_campaign.indexOf('_pd_acq') > -1 || utm_campaign.indexOf('_pd_pmt') > -1){
            DCMActivity(page_view_category, 'hvpla0');
        }

        if (config.GAOBJ[config.HN] && ut.getSess('new')){
//             <!--
//             Start of Floodlight Tag: Please do not remove
//             Activity name of this tag: Main site landing page view
//             URL of the webpage where the tag is expected to be placed: https://www.edf.org/
//                 This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
//             Creation Date: 11/22/2023
//             -->
//             <script type="text/javascript">
//             var axel = Math.random() + "";
//             var a = axel * 10000000000000;
//             document.write('<iframe src="https://4390944.fls.doubleclick.net/activityi;src=4390944;type=PageV0;cat=mains0;u1=[cons_id];u2=[tran_id];u3=[gift_amount];u4=[donation_form];u5=[gift_type];u6=[source];u7=[sub_source];u8=[landing_pg];u9=[landing_pg_1st_visit];u10=[source_1st_visit];u11=[subsource_1st_visit];u12=[conversion_pg];u13=[new_to_file];u14=[campaign];u15=[e_traffic];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?" width="1" height="1" frameborder="0" style="display:none"></iframe>');
//         </script>
//             <noscript>
//                 <iframe src="https://4390944.fls.doubleclick.net/activityi;src=4390944;type=PageV0;cat=mains0;u1=[cons_id];u2=[tran_id];u3=[gift_amount];u4=[donation_form];u5=[gift_type];u6=[source];u7=[sub_source];u8=[landing_pg];u9=[landing_pg_1st_visit];u10=[source_1st_visit];u11=[subsource_1st_visit];u12=[conversion_pg];u13=[new_to_file];u14=[campaign];u15=[e_traffic];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" frameborder="0" style="display:none"></iframe>
//             </noscript>
//             <!-- End of Floodlight Tag: Please do not remove -->

            DCMActivity(page_view_category, 'mains0');
        }

        if (config.isClassy && config.HN.indexOf('edf') > -1){
            DCMActivity(page_view_category, 'Donat0');
        }

        if (config.isClassy && config.isMCAFC3){
            DCMActivity({
                advertiser_id: 5624628,
                type: 'donat0',
                category: 'pagev0'
            });
        }

        if (config.isMCAFC3 && ut.getSess('new')){
            DCMActivity({
                advertiser_id: 5624628,
                type: 'landi0',
                category: 'pagev0'
            });
        }
    });

    if (config.isDev){
        listenForEvent('EDANSignup', console.log);
    }

    //not going to bother with postMessage. very few interactions generate a message back to the parent window, and it looks like we can deduce the overall flow with virtual page tracking
    if (config.isJobs){
        addEvent('message', function(e){
            if (e.origin !== 'https://careers-edf.icims.com'){
                return;
            }

            let data = e.data;
            if (typeof data === "string") {
                try {
                    data = ICIMS.jsonDecode(data);
                } catch (e) {}
            }

            if (readCookie('temp_id') === 't1657201413212'){
                console.log(data);

                if (e.source && typeof e.source === 'object' && e.source.eventData){
                    console.log(e.source);
                }
            }

            // e.source.eventData;
            // e.source.eventName;
            // e.source.eventVendor;
            // e.source.eventVersion;
            // {
            //     "currentStep": 1,
            //     "jobId": 3675,
            //     "stepName": "profileStep",
            //     "customerId": "7575",
            //     "stepId": 1,
            //     "totalSteps": 3,
            //     "trackingSessionId": "7da4f2be-29b3-47ec-8df3-30b0b7bf4a6d",
            //     "locale": "en-US",
            //     "trackingUserId": "168ae729-dee5-49f5-b3ad-108a1e5b2749",
            //     "customerEnv": "PRODUCTION",
            //     "isComplete": false
            // }
        });
    }

    if (config.isSSFH){
        if (acceptsCookies()){
            grantStorage(); //not needed, but being explicit
        }
        else {
            let wait_for_ssfh = setInterval(function(){
                if (acceptsCookies()){
                    clearInterval(wait_for_ssfh);
                    grantStorage();
                }
            }, 1000);
        }

        let path_split = config.PN.split('/');
        if (path_split.length && path_split.length >= 4 && path_split[1] === 'search'){
            GAEvent('view_search_results', {
                search_view_mode: 'page results',
                search_type: path_split[2],
                search_term: path_split[3]
            });
        }

        let search_inputs = $qsAll('form.search-content-form input[type="text"]'),
            keyup_timeout;

        if (search_inputs && search_inputs.length){
            for (let i=0; i<search_inputs.length; i++){
                addEvent(search_inputs[i], 'keyup', function(e){
                    clearTimeout(keyup_timeout);

                    keyup_timeout = setTimeout(function(){
                        GAEvent('view_search_results', {
                            search_view_mode: 'ajax results',
                            search_term: e.target.value
                        });
                    }, 3000);
                });
            }
        }
    }

    let gtranslate_selector = $id('gtranslate_selector');
    if (gtranslate_selector){
        addEvent(gtranslate_selector, 'change', function(e){
            let select = e.target,
                lang_code = select.value,
                lang_label = lang_code,
                index = select.selectedIndex,
                options = select.childNodes;

            if (options[index]){
                lang_label = options[index].innerText || lang_label;
            }

            GAEvent('select_content', {
                content_type: 'language',
                item_id: lang_code,
                language: lang_label
            });
        });
    }

    if (config.isImpact2021){
        _LA('impact2021');

        trackHTML5Player('video', 'impact2021');

        if (!config.isTesting){
            addLoadEvent(function(){
                setTimeout(function(){
                    let slide = $qs('body > .animsition-overlay-slide'),
                        content = $qs('body > .animsition'),
                        slide_class = 'ns-overlay-slide-in-bottom';

                    if (slide && content && !slide.classList.contains(slide_class)){
                        slide.classList.add(slide_class);
                        slide.classList.add('forced');
                        content.style.opacity = '1';
                    }
                }, 5000);
            });
        }
    }

    if (config.isImpact2022 || config.isVision2030){
        _LA('impact2022_and_vision2030');
    }

    if (config.isImpact2022){
        if (!config.isDev){
            let name = "EDF Impact Report 2022 - ",
                isSustainer = config.QS.indexOf('sustainer') > 1 || LS_G('isSustainer');

            if (isMobile()){
                name += 'Mobile';
            }
            else if (isTablet()){
                name += 'Tablet';
            }
            else {
                name += 'Desktop';
            }

            if (isSustainer){
                name += ' Sustainer'
            }

            window.CE_SNAPSHOT_NAME = name;
        }

        addLoadEvent(function(){
            observeElement($qs('.gallery-hero'), {
                track_time: true,
                ec: 'impact2022',
                el: 'hero_animation_in-viewport'
            });
        });
    }

    if (config.isImpact2023) {
        _LA('impact2023');
    }

    if (window.$nuxt
        || config.isReact
        || config.isGCA //including for the air-tracker/map tool
        || config.isJobs
    ) {
        function updateDocTitle(){
            let base_title = document.title;

            if (config.isSustainabuy){
                let breadcrumbs = $qsAll('.MuiTypography-root .MuiBreadcrumbs-li'),
                    headline = $qs('h1') || $qs('h2') || $qs('h3'),
                    text,
                    i;

                if (config.PN === '/'){
                    document.title = base_title;
                }
                else if (breadcrumbs && breadcrumbs.length){
                    let items = [];

                    for (i=0; i<breadcrumbs.length; i++){
                        text = breadcrumbs[i].innerText;
                        if (text && trim(text+'').toLowerCase() !== 'home'){
                            items.push(trim(text));
                        }
                    }

                    document.title = (items.length ? items.join(' > ')+' | ' : '')+base_title;

                    let product,
                        search = $qsAll('.col-12.col-lg-6.row.align-items-start > .col-12.col-md-6.row > div > span'),
                        matches_nav = false;

                    for (i=0; i<items.length; i++){
                        items[i] = items[i].toLowerCase();
                    }

                    for (i=0; i<search.length; i++){
                        text = search[i].innerText;

                        if (text){
                            if (matches_nav){
                                product = text;
                                break;
                            }
                            else if (items.indexOf(trim(text.toLowerCase())) > -1){
                                matches_nav = true;
                            }
                        }
                        else {
                            matches_nav = false;
                        }
                    }

                    if (product){
                        document.title = product+' | '+document.title;
                    }
                }
                else if (headline && headline.innerText){
                    document.title = headline.innerText+' | '+base_title;
                }
                else {
                    document.title = base_title;
                }
            }
        }

        updateDocTitle();
        watchForLocationChanges(config.isGCA || config.isJobs, function(){ //sometimes differences are only in querystring for gca and jobs
            config.PN = location.pathname;

            updateDocTitle();

            //DO NOT trigger virtualPage for GA4 since tracker/stream setting is tracking by default
            if (!config.isGCA){
                virtualPage(config.PN, null, !config.isGCA);
            }
        });
    }

    addReadyEvent(function(){
        if ((config.isEDF || config.isDOF || config.isDOFC4 || config.isMCAF || config.isMRD || config.isMicroSite || $qs('.mc-embedded-subscribe-form') || $qs('.mc4wp-form')) && !config.isUtility) {
            trackForm();

            setTimeout(trackForm, 3000);
            setTimeout(trackForm, 5000);
            setTimeout(trackForm, 10000);
        }

        triggerYouTubeAttach()

        if (config.isMethaneSat) {
            addLoadEvent(triggerYouTubeAttach);
            addEvent('resize', triggerYouTubeAttach);
        }

        classy.attach();
    });

    if (config.main_site){
        if (config.PN === '/'){
            addReadyEvent(function() {
                _LA('homepage');
            });
        }

        // addReadyEvent(function(){
        //     let id = 'hhI',
        //         name = 'classy_embed_test',
        //         bucket = testBucket(id);
        //
        //     experiment({
        //         name: name,
        //         experiment_id: id,
        //         group: bucket
        //     });
        //
        //     if (bucket !== 'control'){
        //         let btn = 'header .donate-and-search .button.mobile',
        //             selector = btn+', '+btn+' span';
        //
        //         if (config.PN === '/'){
        //             selector += ', .c-block.donate-now .donation-span-text'
        //         }
        //
        //         config.hasClassyEmbedVariant = true;
        //
        //         let donate_btn = $qs(btn);
        //         if (donate_btn){
        //             donate_btn.classList.remove('custom_transfer');
        //             donate_btn.dataset.original_url = donate_btn.getAttribute('href');
        //             donate_btn.setAttribute('href', 'javascript:void(0);');
        //             donate_btn.removeAttribute('data-transfer');
        //         }
        //
        //         addLoadEvent(function(){
        //             classy.embed({
        //                 id: 531264,
        //                 selector: selector,
        //                 nudgeTrays: true,
        //                 lazyLoad: !!gup('lazyLoad')
        //             });
        //         });
        //     }
        // });
    }

    if (config.main_site || config.isBlogs){
        ajaxSniffer(function(err, that, url, response){
            if (!err && (url+'').indexOf('/overlay') > -1){ //https://dv8.edf.org/node/23253/overlay
                if (response.indexOf('value="create"') > -1){ //TODO: this works for now, but create a better signal
                    window.ConvioSignup.initialized(function(){
                        window.ConvioSignup.handleTwoStepOverlay(url);
                    });
                }
            }
        });

        addEvent('dynamic_component_display', function(e){
            let detail = e.detail,
                element = detail.element;

            if ('length' in element){
                element = element[0];
            }

            if (element){
                setTimeout(function(){
                    trackPlacement($qs('form', element), detail.type, detail.id);
                }, 750);
            }

            onSubmit();
        });

        addReadyEvent(function(){
            //loading donation_starter on just main site for now
            let proceed_with_donation_starter = true;

            if (window.edf_theme && edf_theme.donation_starter){
                proceed_with_donation_starter = false;
            }

            for (let i=0; i<document.scripts.length; i++){
                let src = document.scripts[i].src+'';
                if (src.indexOf('donation_starter/dist') > -1){
                    proceed_with_donation_starter = false;
                }
            }
            if (proceed_with_donation_starter){
                loadScript(config.assets_path+'donation_starter/dist/js/donation_starter'+jsExt());
            }

            if (config.PN === '/how-we-can-fight-climate-change-misinformation' || ut.getPage('nid') === 27704){
                //TODO: maybe for Sam K.
                //effect here is the same as https://www.edf.org/admin/structure/token-custom/manage/misinformation_brigade_shout_card_signup/edit?destination=/admin/structure/token-custom, but the layout nature of the page does not make it easy to include this

                let token = `<div class="front">
                            <div class="ConvioSignup" data-addl="misinformation_brigade_token" data-lists="50327"></div>
                            <p class="confirmation" style="display:none;"><em>Thanks for subscribing to the Misinformation Brigade.</em></p>
                        </div>`;

                let btn = $qs('.c-block--shout a.button');

                if (btn){
                    let parent = btn.parentNode;

                    if (parent){
                        let outer = parent.parentNode;

                        outer.removeChild(parent);

                        appendHTML({
                            a: outer,
                            elem: 'div',
                            innerHTML: token,
                        });

                        //injectCSS('.front .c-block--sign-up .c-block--sign-up__form-fields {margin-top: 0 !important}');
                        injectCSS('.c-block--cta.c-block .section-header__description {margin-bottom: 0 !important}');

                        ConvioSignup.initialized(function(){
                            ConvioSignup.attach();
                        });
                    }
                }
            }
        });

        addLoadEvent(function(){
            let nav = $id('page-nav');
            if (config.PN.indexOf('/about/our-sustainability-data') === 0 && nav){ //could be /about/our-sustainability-data/
                forAll(nav, 'a', function(link){
                    addEvent(link, 'click', function(){
                        GAEvent('click', {
                            ec: 'sustainability_dash',
                            at: 'nav_menu',
                            af: link.id || link.innerText+''
                        });
                    });
                });
            }

            if (!config.isDev && window.LOAD_CE && !config.DNT && acceptsCookies()){
                loadScript(config.CE);
            }
        });
    }

    if (!config.DNT && config.isBlogs && config.isProd){
        addReadyEvent(function(){
            if (!window.CE2 && acceptsCookies()){
                if (config.PN.indexOf('/energyexchange') === 0){
                    loadScript('//script.crazyegg.com/pages/scripts/0069/2250.js');
                }
                else if (config.PN.indexOf('/markets') === 0 || config.PN.indexOf('/edfish') === 0 || config.PN.indexOf('/health') === 0 || config.PN.indexOf('/climate411') === 0){
                    loadScript(config.CE);
                }
            }
        });
    }

    if (!config.DNT && config.isBiz && config.isProd){
        addReadyEvent(function() {
            if (!window.CE2 && acceptsCookies()) {
                loadScript(config.CE);
            }
        });
    }

    if (config.isBPITF) {
        ajaxSniffer(function (err, that) {
            if (that && typeof that === 'object' && that.__sentry_xhr__ && that.__sentry_xhr__) {
                let xhr = that.__sentry_xhr__;

                if (xhr.body && (xhr.url+'').indexOf('rudderstack') > -1){
                    let rudder = xhr.body;

                    try {
                        rudder = JSON.parse(rudder);
                    } catch(e){}

                    if (typeof rudder === 'object' && rudder && rudder.event){
                        let event = rudder.event,
                            props = rudder.properties;

                        if (event.indexOf('conversation') > -1 || event.indexOf('answered') > -1 || event.indexOf('submi') > -1){
                            GAEvent('typeform_'+event, {
                                ec: 'typeform',
                                el: props.form_uid,
                                at: props.block_type || props.form_status,
                                af: props.block_type ? props.block_ref+' ['+props.block_index+']' : props.typeform_view_id
                            })
                        }
                    }
                }
            }
        });
    }

    let clarity_project_id = null;

    if (!config.isDev){
        if (config.main_site){
            clarity_project_id = '9y1jxidpa1';
        }
        else if (config.edaf_main_site){
            clarity_project_id = 'aktjgwsx04';
        }
        else if (config.isBlogs){
            clarity_project_id = 'eu79zac9x2'
        }
        else if (config.isVision2030){
            clarity_project_id = 'as9j9m7py3';
        }
        else if (config.isVS){
            clarity_project_id = 'ht0ril8ael';
        }
        else if (config.isEET){
            clarity_project_id = 'dxvt34m0z3';
        }
        else if (config.isMethaneSat){
            clarity_project_id = 'avc2j6xnyu';
        }
        else if (config.isElectricFleet){
            clarity_project_id = 'axxf63grrk';
        }
        else if (config.isImpact2021){
            clarity_project_id = 'b590j446kr';
        }
        else if (config.HN.indexOf('betterhubs') > -1){
            clarity_project_id = 'evsvzrsldh';
        }
        else if (config.HN === 'edf.giftplans.org'){
            clarity_project_id = 'hivq7i83x6';
        }
        else if (config.isImpact2022){
            clarity_project_id = 'exqr4pbd6c';
        }
        else if (config.isImpact2023){
            clarity_project_id = 'jnejwwqbn1';
        }
        else if (config.isFTA){
            clarity_project_id = 'iu2vygtaqx';
        }
        else if (config.isClassy && opts.brand){
            let brand = opts.brand;
            if (brand === 'edf'){
                clarity_project_id = "akpm8tbilt";
            }
            else if (brand === 'edfaction'){
                clarity_project_id = "akteq02pey";
            }
            else if (brand === 'edfactionpac'){
                clarity_project_id = "akthpap1qc";
            }
            else if (brand === 'mcaf'){
                clarity_project_id = "aktgn6moc4";
            }
            else if (brand === 'cama'){
                clarity_project_id = "aktgwnr7n1";
            }
            else if (brand === 'dof'){
                clarity_project_id = "akth6dw2dp";
            }
            else if (brand === 'dofaction'){
                clarity_project_id = "akthcswcgq";
            }
            else if (brand === 'edfactiontxpac'){
                clarity_project_id = "bzfhht0b2n";
            }
        }
        else if (config.isNZA){
            clarity_project_id = 'i8lkb6czed';
        }
        else if (config.isBiz){
            clarity_project_id = 'i8ll1whrwg';
        }
    }
    //CAUTION: in isDev space
    else if (config.isImpact2022){
        clarity_project_id = 'ekf0t71j1u';
    }
    //CAUTION: in isDev space

    if (!config.DNT && clarity_project_id){
        addLoadEvent(function(){
            (function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", clarity_project_id);

            function consent(){
                //https://learn.microsoft.com/en-us/clarity/setup-and-installation/cookie-consent#how-to-instruct-clarity-not-to-place-a-cookie-until-you-obtain-consent

                if (window.clarity){
                    window.clarity('consent');
                }
            }

            if (acceptsCookies()){
                consent();
            }

            addEvent('cookies_accepted', consent);
        });
    }

    if (!config.DNT && (config.edaf_main_site
        && config.PN.indexOf('/sustainable-agriculture') === 0)
        || (config.isBiz && (ut.getPage('nid') === 25845 || config.PN.indexOf('agricultural-finance') > -1)) //ut.getPage('nid') not updating in time, might need to wait for addReadyEvent
    ){
        loadScript('https://www.edf.org/assets/sustainable_ag_signal/js/tracking.js');
    }

    if (config.edaf_main_site){
        window.signal_preprocess = function(form){
            let vol = form.find('input[name="interest_in_volunteering"]');
            if (vol.length){
                let list = vol.attr('data-list');
                if (list){
                    let lists = form.find('input[name="lists"]');

                    if (lists){
                        let lists_value = lists.val() || '';

                        if (vol.is(':checked')){
                            if (lists_value.indexOf(','+list) === -1){
                                lists_value += ','+list;
                            }
                        }
                        else {
                            lists_value = lists_value.replace(','+list, '');
                        }

                        lists.val(lists_value);
                    }
                }
            }

            let data = $EDF.formDataToJSON(form),
                opt = form.find('input[name="accept_email"]');

            if (opt.length){
                data.accept_email = !!opt.is(':checked');
            }

            return data;
        };

        window.signal_conversion = function(form, data, response){
            form = form[0]; //de-jquerify
            //data = data || $EDF.formDataToJSON(form);

            let id = form.getAttribute('id')+'',
                page = '',
                stm_base = 'https://signal-link.io/?camp=uu3v&stm_campaign=sustainableag&stm_category=',
                stm;

            if (id.indexOf('national') > -1){
                page = 'national';
            }
            else if (id.indexOf('arkansas') > -1){
                page = 'arkansas';
            }
            else if (id.indexOf('georgia') > -1){
                page = 'georgia';
            }
            else if (id.indexOf('nebraska') > -1){
                page = 'nebraska';
            }

            if (page){
                if (id.indexOf('action') > -1){ //petition
                    stm = 'petition';
                }
                else if (id.indexOf('signup') > -1){ //bottom signup
                    stm = 'email';
                }
                else if (id.indexOf('modal') > -1){ //bottom signup
                    stm = 'modal';
                }
            }
            else if (id.indexOf('policy') > -1){
                page = 'policy';

                if (id.indexOf('signup') > -1){ //bottom signup
                    stm = 'email';
                }
            }

            if (stm === 'modal'){ //NOTE: signal changed course and sourced the same stm link for all pages...so it deviates from the prior pattern
                pixeler({url: stm_base+'download&stm_medium=pdfdownload&dest=pdf_download', dnt: 1});

                let filename = 'Conservation-Impact-On-Farm-Bottom-Line-2021.pdf';
                download("https://www.edf.org/sites/default/files/content/"+filename, filename);
            }
            else if (stm){
                pixeler({url: stm_base+'formsubmission&stm_medium='+stm+'signup&stm_page='+page+'&stm_phase=b&dest='+page+'page', dnt: 1});
            }

            pixeler({url: 'https://clickserv.sitescout.com/conv/4fafb2b657a3fd45', dnt: 1});
        };
    }

    if (config.isMM){
        _LA('methanemoment');
    }

    if ((config.isImpact2021 || config.isImpact2022 || config.isImpact2023 || config.isVision2030 || config.isVS || config.isEDFCS) && !config.isDev){
        if (!config.DNT && acceptsCookies()){
            addLoadEvent(function(){
                loadScript(config.CE);
            });
        }
    }

    if (config.isVS){
        _LA('vital_signs');
        trackHTML5Player('audio', 'vital_signs');
    }

    if (config.isNZA){
        _LA('netzeroaction');
    }

    addReadyEvent(function(){
        //examples
        //https://business.edf.org/the-inflation-reduction-act-a-snapshot-for-business
        //https://www.cleanbeautyjustice.org/

        if ($qs('.gform_wrapper')){
            jQueryOnLoad(function($){
                let gform_confirmation_loaded = 'gform_confirmation_loaded',
                    gravityFormConfirmationLoaded = 'gravityFormConfirmationLoaded',
                    last_form_id = null,
                    last_form_data = {};

               if (gform && typeof gform.addAction === 'function'){
                   gform.addAction('gform_input_change', function(elem, formId, fieldId) {
                       last_form_id = formId;

                       last_form_data = formDataToJSON($id('gform_'+last_form_id));
                   });
               }

               $(document).on(gform_confirmation_loaded, function(event){
                   if (config.isDev || config.isTesting){
                       console.log(event);
                       console.log(last_form_data);
                   }

                   GAEvent('submit', {at: 'gform', af: last_form_id}); //CAUTION: will appear as duplicate of submit happen that gets triggered by simply submitting the form

                   dataLayer.push({
                       event: gravityFormConfirmationLoaded,
                       event_name: gform_confirmation_loaded,
                       event_focus: last_form_id
                   });

                   if (config.isElectricFleet && config.PN.indexOf('/register') === 0){
                       GAEvent('sign_up', {
                           dno: true,
                           ec: 'electricfleet',
                           at: 'registration',
                           el: config.PN,
                           conversion: true
                       });
                   }
                   else if (config.isEDFCS){
                       GAEvent('submit', {ec: 'Climate Solutions', af: 'contact_us'});

                       dataLayer.push({
                           event: gravityFormConfirmationLoaded,
                           event_name: 'edfcs_contact_form'
                       });
                   }
               });
           });
       }
    });

    if (config.isClassy){
        //only handles cc payment entry for now
        addEvent('message', function(e){
            if (e.origin !== 'https://js.stripe.com'){
                return;
            }

            if (!e.data){
                return;
            }

            let data = null;
            try {
                data = JSON.parse(e.data);
            } catch(e){}

            if (!data){
                return;
            }

            // let _data = {
            //     "type": "inner",
            //     "controllerId": "__privateStripeController0701",
            //     "frameId": "__privateStripeFrame07011",
            //     "message": {
            //         "action": "stripe-frame-event",
            //         "payload": {
            //             "event": "change",
            //             "data": {
            //                 "complete": true,
            //                 "value": {
            //                     "postalCode": ""
            //                 },
            //                 "empty": false,
            //                 "brand": "amex"
            //             }
            //         }
            //     },
            //     "__stripeJsV3": true
            // }

            if (window.EDFClassy
                && typeof EDFClassy.getFormData === 'function'
                && data.message
                && typeof data.message === 'object'
                && typeof data.message.payload === 'object'
                && data.message.payload.event === 'change'
                && typeof data.message.payload.data === 'object'
                && data.message.payload.data.complete
            ){
                let data = EDFClassy.getFormData();
                GAEvent("add_payment_info", {
                    currency: 'USD',
                    value: data.amount,
                    items: [get_ecommerce_item()],
                    payment_type: data.payment_type || 'unknown'
                });
            }
        });
    }

    addReadyEvent(function(){
        forAll('.active_p2a_campaigns', activeP2ACampaigns);
        forAll('.active_ea_campaigns', activeP2ACampaigns);
        forAll('.mobilize_events', mobilizeEvents);

        if ($qs('.ConvioSignup') || (config.isEDAF && !config.isEA && !config.isClassy) || (config.main_site && config.isDev && config.isTesting)) {
            if (window.ConvioSignup){
                window.ConvioSignup.initialized();
            }
        }

        let surveyContainer = $qs('.surveyEDF');
        if (surveyContainer && surveyContainer.getAttribute('data-id')) {
            loadScript(config.assets_path+'survey_builder/dist/js/survey_builder' + jsExt());
        }

        forAll('.ea_embed', function(elem){
            let form = elem.getAttribute('data-form');
            if (form){
                everyaction.appendEATag(elem, form);
            }
        });
    });

    addReadyEvent(function(){
        let wpcf7 = 'wpcf7';

        forAll('.'+wpcf7, function(form){
            //https://contactform7.com/dom-events/
            [wpcf7+'invalid', wpcf7+'spam', wpcf7+'mailsent', wpcf7+'mailfailed', wpcf7+'submit'].forEach(function(event){
                addEvent(form, event, function(evt){
                    if (config.log){
                        console.log(evt);
                    }

                    let detail = evt.detail,
                        type = evt.type || null;

                    if (!detail || !type){
                        return;
                    }

                    let message = detail.apiResponse.message || null,
                        status = detail.status || null,
                        unitTag = detail.unitTag || null,
                        contactFormId = detail.contactFormId || null,
                        has_inputs = Array.isArray(detail.inputs) && detail.inputs.length,
                        zero_p_data = {};

                    if (has_inputs){
                        for (let i=0; i<detail.inputs.length; i++){
                            let name = detail.inputs[i].name+'',
                                value = detail.inputs[i].value;

                            if (((name)+'').indexOf('email') > -1 && isEmail(value)){
                                ut.setUser('email', value);
                            }

                            if (name.indexOf('ak_') !== 0 && value){
                                zero_p_data[name] = typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value;
                            }
                        }
                    }

                    dataLayer.push({
                        event: type,
                        unitTag: unitTag,
                        status: status,
                        contactFormId: contactFormId,
                        apiResponseMessage: message
                    });

                    if (has_inputs){
                        ut.push({
                            event: 'zero_party_collection',
                            ec: wpcf7,
                            el: status,
                            at: type,
                            af: unitTag,
                            zero_data: zero_p_data
                        });
                    }

                    GAEvent(wpcf7+'_submit', {
                        at: type,
                        af: unitTag,
                        ec: wpcf7,
                        el: message
                    });

                    if (type === wpcf7+'submit' && status !== 'validation_failed'){
                        //for ACQ CB from email `Defend Our Future - Ad Tracking Questions`
                        let community_fellow = config.PN.indexOf('/community-fellow') === 0,
                            campus_ambassador = config.PN.indexOf('/campus-ambassador') === 0;

                        if (config.isDOF && (community_fellow || campus_ambassador)){
                            let event = 'dof_'+(community_fellow ? 'community_fellow' : 'campus_ambassador')+'_application';

                            //community_fellow: https://www.linkedin.com/campaignmanager/accounts/506594522/conversions/11553049?offline=false&step=1
                            //campus_ambassador: https://www.linkedin.com/campaignmanager/accounts/506594522/conversions/10705250?offline=false&step=1
                            linkedinConversion(community_fellow ? 11553049 : 10705250);

                            //https://developers.facebook.com/docs/meta-pixel/reference#standard-events
                            facebookEvent('SubmitApplication', {content_category: event});

                            GAEvent(event, {
                                at: type,
                                af: unitTag,
                                el: message
                            });
                        }
                    }
                });
            });
        });
    });
}

//currently just serving sustainable agriculture stm link tracking
export function edfActionClicks(e){
    let href = (e.target.href+'').trim(),
        sustain_ag = '/sustainable-agriculture';

    if (config.PN.indexOf(sustain_ag) === 0 && href.indexOf('#join-us') > -1){
        let stm = '',
            page = config.PN.split('/');

        if (config.PN === sustain_ag){
            stm = 'national';
        }
        else if (page.length > 2 && ['national', 'georgia', 'arkansas', 'nebraska'].indexOf(page[2]) > -1){
            stm = page[2];
        }

        if (stm){
            pixeler('https://signal-link.io/?camp=uu3v&stm_campaign=sustainableag&stm_category=formsubmission&stm_medium=joinus&stm_page='+stm+'&stm_phase=b&dest='+stm+'page');
        }
    }
}

let classy_added_to_cart = false;
export function classyClicks(e){  //TODO
    if (gti(config.pageStartTime) > 9000
        && !classy_added_to_cart
        && window.EDFClassy
        && typeof EDFClassy.getFormData === 'function'
    ){
        classy_added_to_cart = true;

        GAEvent("add_to_cart", {
            currency: 'USD',
            value: EDFClassy.getFormData().amount,
            items: [get_ecommerce_item()]
        });
    }
}